var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.module && _vm.control
    ? _c("div", { staticClass: "cachelist" }, [
        _c(
          "div",
          {
            style: {
              overflow: "hidden",
              width: "100%",
              height: !_vm.control.ms ? _vm.view["ms"] + "px" : "0px",
              opacity: !_vm.control.ms ? "1" : "0",
            },
            attrs: { id: "ms" },
          },
          [
            _c("div", { staticClass: "text_title" }, [
              _vm._v(" 案情描述 "),
              _c("div", { staticClass: "opc_text" }, [
                _vm._v("accident description"),
              ]),
              _vm.$route.query.is_create.toString() === "1"
                ? _c(
                    "div",
                    { staticClass: "for_select" },
                    [
                      _c("van-checkbox", {
                        attrs: { "checked-color": "#F01C1C" },
                        model: {
                          value: _vm.control.ms,
                          callback: function ($$v) {
                            _vm.$set(_vm.control, "ms", $$v)
                          },
                          expression: "control.ms",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "text_title_content" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$route.query.is_create.toString() === "1"
                      ? this.$store.state.ana.caseName
                      : _vm.params.caseName
                  ) +
                  " "
              ),
            ]),
          ]
        ),
        _vm.module.case.length > 0 && _vm.parentFlags["case"]
          ? _c(
              "div",
              { ref: "case", staticClass: "appeal" },
              [
                _c("div", { staticClass: "appeal_title" }, [
                  _vm._v(" 相似判例 "),
                  !_vm.control.case.flag
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.case.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.case, "flag", $$v)
                              },
                              expression: "control.case.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("case")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.case, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.case.children[index].flag,
                          expression: "!control.case.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "des_item",
                      attrs: { div: index },
                    },
                    [
                      _c("div", { staticClass: "_title" }, [
                        _vm._v(_vm._s(item.case_name)),
                      ]),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select_child" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.case.children[index].flag,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.control.case.children[index],
                                      "flag",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "control.case.children[index].flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "bottom_text" }, [
                        _vm._v(_vm._s(item.court_name + " / " + item.pub_date)),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        (_vm.module.nlaw.length > 0 && _vm.nlaw.flag) || !_vm.control.nlaw.flag
          ? _c(
              "div",
              { ref: "nlaw", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 辩论使用的法律法规 "),
                  _vm.$route.query.is_create.toString() === "1"
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          !_vm.control.nlaw.flag
                            ? _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.nlaw.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.control.nlaw, "flag", $$v)
                                  },
                                  expression: "control.nlaw.flag",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._l(_vm.module.nlaw, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.control.nlaw.children[index].flag ||
                            _vm.nlaw.nums.indexOf(index) > -1,
                          expression:
                            "!control.nlaw.children[index].flag || nlaw.nums.indexOf(index) > -1",
                        },
                      ],
                      key: index,
                      staticClass: "ts",
                    },
                    [
                      _c("div", { staticClass: "_title" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _c("span", [_vm._v(_vm._s(item.percent))]),
                        _vm.$route.query.is_create.toString() === "1"
                          ? _c(
                              "div",
                              { staticClass: "for_select" },
                              [
                                !_vm.control.nlaw.children[index].flag
                                  ? _c("van-checkbox", {
                                      attrs: { "checked-color": "#F01C1C" },
                                      model: {
                                        value:
                                          _vm.control.nlaw.children[index].flag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.control.nlaw.children[index],
                                            "flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "control.nlaw.children[index].flag",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._l(item.data, function (element, num) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.control.nlaw.children[index].children[
                                    num
                                  ].flag,
                                expression:
                                  "!control.nlaw.children[index].children[num].flag",
                              },
                            ],
                            key: num,
                            staticClass: "items",
                          },
                          [
                            _c("div", { staticClass: "Title" }, [
                              _c("span", [_vm._v(_vm._s(element.title))]),
                              _c("span", [_vm._v(_vm._s(element.percent))]),
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c(
                                    "div",
                                    { staticClass: "for_select" },
                                    [
                                      _c("van-checkbox", {
                                        attrs: { "checked-color": "#F01C1C" },
                                        model: {
                                          value:
                                            _vm.control.nlaw.children[index]
                                              .children[num].flag,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.control.nlaw.children[index]
                                                .children[num],
                                              "flag",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "control.nlaw.children[index].children[num].flag",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "center",
                                },
                              },
                              [!element.text ? _c("van-loading") : _vm._e()],
                              1
                            ),
                            element.text.length > 0
                              ? _c("div", { staticClass: "content" }, [
                                  _vm._v(" " + _vm._s(element.text) + " "),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        (_vm.module.law.length > 0 && _vm.law.flag) || !_vm.control.law.flag
          ? _c(
              "div",
              { ref: "law", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 辩论使用的法律法规 "),
                  _vm.$route.query.is_create.toString() === "1"
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          !_vm.control.law.flag
                            ? _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.law.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.control.law, "flag", $$v)
                                  },
                                  expression: "control.law.flag",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._l(_vm.module.law, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.control.law.children[index].flag ||
                            _vm.law.nums.indexOf(index) > -1,
                          expression:
                            "!control.law.children[index].flag || law.nums.indexOf(index) > -1",
                        },
                      ],
                      key: index,
                      staticClass: "ts",
                    },
                    [
                      _c("div", { staticClass: "_title" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _c("span", [_vm._v(_vm._s(item.percent))]),
                        _vm.$route.query.is_create.toString() === "1"
                          ? _c(
                              "div",
                              { staticClass: "for_select" },
                              [
                                !_vm.control.law.children[index].flag
                                  ? _c("van-checkbox", {
                                      attrs: { "checked-color": "#F01C1C" },
                                      model: {
                                        value:
                                          _vm.control.law.children[index].flag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.control.law.children[index],
                                            "flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "control.law.children[index].flag",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._l(item.data, function (element, num) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.control.law.children[index].children[num]
                                    .flag,
                                expression:
                                  "!control.law.children[index].children[num].flag",
                              },
                            ],
                            key: num,
                            staticClass: "items",
                          },
                          [
                            _c("div", { staticClass: "Title" }, [
                              _c("span", [_vm._v(_vm._s(element.title))]),
                              _c("span", [_vm._v(_vm._s(element.percent))]),
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c(
                                    "div",
                                    { staticClass: "for_select" },
                                    [
                                      _c("van-checkbox", {
                                        attrs: { "checked-color": "#F01C1C" },
                                        model: {
                                          value:
                                            _vm.control.law.children[index]
                                              .children[num].flag,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.control.law.children[index]
                                                .children[num],
                                              "flag",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "control.law.children[index].children[num].flag",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "center",
                                },
                              },
                              [!element.text ? _c("van-loading") : _vm._e()],
                              1
                            ),
                            element.text.length > 0
                              ? _c("div", { staticClass: "content" }, [
                                  _vm._v(" " + _vm._s(element.text) + " "),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.focus.length > 0 && _vm.parentFlags["focus"]
          ? _c(
              "div",
              { ref: "focus", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 争议焦点 "),
                  !_vm.control.focus.flag
                    ? _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            click: function ($event) {
                              _vm.stopUpdate = true
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.focus.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.focus, "flag", $$v)
                              },
                              expression: "control.focus.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("focus")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.focus, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.focus.children[index].flag,
                          expression: "!control.focus.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "focus_items",
                    },
                    [
                      _vm._l(item.title, function (_item, num) {
                        return _c("div", { key: num, staticClass: "_title" }, [
                          _vm._v(_vm._s(_item)),
                        ])
                      }),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            {
                              staticClass: "for_select",
                              on: {
                                click: function ($event) {
                                  _vm.stopUpdate = true
                                },
                              },
                            },
                            [
                              !_vm.control.focus.children[index].flag
                                ? _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.control.focus.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.control.focus.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "control.focus.children[index].flag",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "tip_line" }, [
                        _c("div", [
                          _c("i", { staticClass: "iconfont icon-fenxi" }),
                          _c("span", [_vm._v(_vm._s(item.percent))]),
                        ]),
                      ]),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.evt.length > 0 && _vm.parentFlags["evt"]
          ? _c(
              "div",
              { ref: "evt", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 证据名称 "),
                  !_vm.control.evt.flag
                    ? _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            click: function ($event) {
                              _vm.stopUpdate = true
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.evt.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.evt, "flag", $$v)
                              },
                              expression: "control.evt.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("evt")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.evt, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.evt.children[index].flag,
                          expression: "!control.evt.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "focus_items",
                    },
                    [
                      _vm._l(item.title, function (_item, num) {
                        return _c("div", { key: num, staticClass: "_title" }, [
                          _vm._v(_vm._s(_item)),
                        ])
                      }),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.evt.children[index].flag,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.control.evt.children[index],
                                      "flag",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "control.evt.children[index].flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "tip_line" }, [
                        _c("div", [
                          _c("i", { staticClass: "iconfont icon-fenxi" }),
                          _c("span", [_vm._v(_vm._s(item.percent))]),
                        ]),
                      ]),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.lawyer.length > 0 && _vm.parentFlags.lawyer
          ? _c(
              "div",
              { ref: "lawyer", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 诉请裁判 "),
                  !_vm.control.lawyer.flag
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.lawyer.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.lawyer, "flag", $$v)
                              },
                              expression: "control.lawyer.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("lawyer")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.lawyer, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.lawyer.children[index].flag,
                          expression: "!control.lawyer.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "lawyer_item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            click: function ($event) {
                              _vm.stopUpdate = true
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.lawyer.children[index].flag,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.control.lawyer.children[index],
                                  "flag",
                                  $$v
                                )
                              },
                              expression: "control.lawyer.children[index].flag",
                            },
                          }),
                        ],
                        1
                      ),
                      item.原审诉讼请求.length > 0
                        ? _c("div", { staticClass: "text_area" }, [
                            _c("div", { staticClass: "_title" }, [
                              _vm._v("原审诉讼请求"),
                            ]),
                          ])
                        : _vm._e(),
                      item.原审诉讼请求.length > 0
                        ? _c("span", [
                            _c(
                              "ul",
                              { staticClass: "appeal_title" },
                              _vm._l(item.原审诉讼请求, function (_item, num) {
                                return _c("li", { key: num }, [
                                  _vm._v(
                                    _vm._s(num + 1) + " . " + _vm._s(_item)
                                  ),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                      item.上诉请求.length > 0
                        ? _c("div", { staticClass: "_title" }, [
                            _vm._v("上诉请求"),
                          ])
                        : _vm._e(),
                      _vm._l(item.上诉请求, function (_item, num) {
                        return _c("span", { key: num }, [
                          _c("div", { staticClass: "request" }, [
                            _vm._v(_vm._s(num + 1) + " . " + _vm._s(_item)),
                          ]),
                        ])
                      }),
                      item.原审裁判结果.length > 0
                        ? _c("div", { staticClass: "_title" }, [
                            _vm._v("原审裁判结果"),
                          ])
                        : _vm._e(),
                      item.原审裁判结果.length > 0
                        ? _c(
                            "span",
                            _vm._l(item.原审裁判结果, function (_item, num) {
                              return _c(
                                "div",
                                { key: num, staticClass: "deem" },
                                [
                                  _vm._v(
                                    _vm._s(num + 1) + " . " + _vm._s(_item)
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      item.法院判决.length > 0
                        ? _c("div", { staticClass: "_title" }, [
                            _vm._v("法院判决"),
                          ])
                        : _vm._e(),
                      item.法院判决.length > 0
                        ? _c(
                            "span",
                            _vm._l(item.法院判决, function (_item, num) {
                              return _c(
                                "div",
                                { key: num, staticClass: "deem" },
                                [
                                  _vm._v(
                                    _vm._s(num + 1) + " . " + _vm._s(_item)
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.deem.length > 0 && _vm.parentFlags.deem
          ? _c(
              "div",
              { ref: "deem", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 本院认为 "),
                  !_vm.control.deem.flag
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.deem.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.deem, "flag", $$v)
                              },
                              expression: "control.deem.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("deem")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.deem, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.deem.children[index].flag,
                          expression: "!control.deem.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "deem_item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text",
                          style: { "-webkit-line-clamp": item.flag ? "" : "4" },
                        },
                        [_vm._v(_vm._s(item.text))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            click: function ($event) {
                              _vm.stopUpdate = true
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.deem.children[index].flag,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.control.deem.children[index],
                                  "flag",
                                  $$v
                                )
                              },
                              expression: "control.deem.children[index].flag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "more",
                          style: {
                            right: item.text.length < 20 ? "0.8rem" : "",
                          },
                          on: {
                            click: function ($event) {
                              item.flag = !item.flag
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.flag ? "收起" : "展开"))]
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.litigation.length > 0 && _vm.parentFlags.litigation
          ? _c(
              "div",
              { ref: "litigation", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 诉讼时间 "),
                  !_vm.control.litigation.flag
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.control.litigation.flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.control.litigation, "flag", $$v)
                              },
                              expression: "control.litigation.flag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("litigation")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.litigation, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.litigation.children[index].flag,
                          expression:
                            "!control.litigation.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "time_items",
                    },
                    [
                      _c("div", { staticClass: "time_title" }, [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        !_vm.control.litigation.children[index].flag
                          ? _c(
                              "div",
                              { staticClass: "for_select" },
                              [
                                _c("van-checkbox", {
                                  attrs: { "checked-color": "#F01C1C" },
                                  model: {
                                    value:
                                      _vm.control.litigation.children[index]
                                        .flag,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.control.litigation.children[index],
                                        "flag",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "control.litigation.children[index].flag",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "vcharts" },
                        [
                          _c("VeHistogram", {
                            attrs: {
                              tooltip: item.params.tooltip,
                              "tooltip-visible": false,
                              grid: item.params.grid,
                              xAxis: item.params.xAxis,
                              yAxis: item.params.yAxis,
                              series: item.params.series,
                              settings: item.params,
                              colors: ["#4B9EFB", "#2DC1E7", "#C0DCFB"],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.module.body.length > 0 && _vm.parentFlags.body
          ? _c(
              "div",
              { ref: "body", staticClass: "lawyer" },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 主体判例 "),
                  _vm.$route.query.is_create.toString() === "1"
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          !_vm.control.body.flag
                            ? _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.body.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.control.body, "flag", $$v)
                                  },
                                  expression: "control.body.flag",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "for_select",
                          on: {
                            "!click": function ($event) {
                              return _vm.BecomeTrue("body")
                            },
                          },
                        },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.InvaildFlag,
                              callback: function ($$v) {
                                _vm.InvaildFlag = $$v
                              },
                              expression: "InvaildFlag",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _vm._l(_vm.module.body, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.control.body.children[index].flag,
                          expression: "!control.body.children[index].flag",
                        },
                      ],
                      key: index,
                      staticClass: "body_item",
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.control.body.children[index].flag,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.control.body.children[index],
                                      "flag",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "control.body.children[index].flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }