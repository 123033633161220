var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ctn" },
    [
      _c("div", { ref: "edit", staticClass: "edit" }, [
        _c(
          "div",
          { ref: "create", staticClass: "create" },
          [
            _vm.$route.query.is_create.toString() === "1"
              ? _c(
                  "fb-header",
                  { attrs: { slot: "header", title: "" }, slot: "header" },
                  [
                    _c("fb-button", {
                      attrs: {
                        slot: "left",
                        icon: "back",
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                      slot: "left",
                    }),
                    _c("template", { slot: "right" }, [
                      _c(
                        "span",
                        { staticClass: "share", on: { click: _vm.collection } },
                        [_vm._v("收藏")]
                      ),
                      _c(
                        "span",
                        { staticClass: "share", on: { click: _vm.download } },
                        [_vm._v("下载")]
                      ),
                      _c(
                        "span",
                        { staticClass: "share", on: { click: _vm.submit } },
                        [_vm._v("分享")]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "title" }, [_vm._v("案情分析报告")]),
            _c("div", { staticClass: "name_area" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$route.query.is_create.toString() === "1"
                      ? this.$store.state.user.userInfo.nickName
                      : _vm.params.name
                  )
                ),
              ]),
              _vm.$route.query.is_create.toString() === "1"
                ? _c("span", [
                    _vm._v(
                      _vm._s(new Date().getFullYear()) +
                        "." +
                        _vm._s(new Date().getMonth() + 1) +
                        "." +
                        _vm._s(new Date().getDate())
                    ),
                  ])
                : _c("span", [_vm._v(_vm._s(_vm.params.date))]),
            ]),
            _c("div", { staticClass: "think" }, [
              _c("div", [
                _vm._v(
                  " 根据您提供的案情，通过大数据人工智能技术处理，找到相似判例，并进行专业分析，得出如下结论： "
                ),
              ]),
              _c(
                "div",
                {
                  ref: "think",
                  staticClass: "xiangfa",
                  attrs: {
                    contenteditable:
                      _vm.$route.query.is_create.toString() === "1",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$route.query.is_create.toString() === "1"
                          ? "在此输入想法"
                          : _vm.params.think
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                style: {
                  overflow: "hidden",
                  width: "100%",
                  height: _vm.flags.ms ? _vm.HelpAnimation["ms"] + "px" : "0px",
                  opacity: _vm.flags.ms ? "1" : "0",
                },
                attrs: { id: "ms" },
              },
              [
                _c("div", { staticClass: "text_title" }, [
                  _vm._v(" 案情描述 "),
                  _c("div", { staticClass: "opc_text" }, [
                    _vm._v("accident description"),
                  ]),
                  _vm.$route.query.is_create.toString() === "1"
                    ? _c(
                        "div",
                        { staticClass: "for_select" },
                        [
                          _c("van-checkbox", {
                            attrs: { "checked-color": "#F01C1C" },
                            model: {
                              value: _vm.flags.ms,
                              callback: function ($$v) {
                                _vm.$set(_vm.flags, "ms", $$v)
                              },
                              expression: "flags.ms",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "text_title_content" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$route.query.is_create.toString() === "1"
                          ? this.$store.state.ana.caseName
                          : _vm.params.caseName
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "space" }, [
              _vm._v(
                " 根据您提供的案情，通过大数据人工智能技术处理，找到相似判例，并进行专业分析，得出如下结论： "
              ),
            ]),
            _vm.data.case.length > 0
              ? _c(
                  "div",
                  {
                    ref: "case",
                    staticClass: "appeal",
                    style: {
                      height: _vm.flags.case.flag
                        ? _vm.HelpAnimation["case"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.case.flag ? "1" : "0",
                      margin: _vm.flags.case.flag ? "" : "0px",
                    },
                  },
                  [
                    _c("div", { staticClass: "appeal_title" }, [
                      _vm._v(" 相似判例 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.case.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.case, "flag", $$v)
                                  },
                                  expression: "flags.case.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.case, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "des_item",
                          style: {
                            overflow: "hidden",
                            height: _vm.flags.case.children[index].flag
                              ? _vm.HelpAnimation["case"].children[index].hgt +
                                "px"
                              : "0px",
                            margin: _vm.flags.case.children[index].flag
                              ? ""
                              : "0px",
                            opacity: _vm.flags.case.children[index].flag
                              ? "1"
                              : "0",
                          },
                          attrs: { div: index },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "_title",
                              on: {
                                click: function ($event) {
                                  return _vm.GoDetail(
                                    item.doc_id,
                                    item.case_name,
                                    "case"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.case_name) + " ")]
                          ),
                          _vm.$route.query.is_create.toString() === "1"
                            ? _c(
                                "div",
                                { staticClass: "for_select_child" },
                                [
                                  _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.flags.case.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.case.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.case.children[index].flag",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "bottom_text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.court_name + " / " + item.pub_date
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("case")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.nlaw.length > 0
              ? _c(
                  "div",
                  {
                    ref: "nlaw",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.nlaw.flag
                        ? _vm.HelpAnimation["nlaw"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.nlaw.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 辩论使用的法律法规 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            {
                              staticClass: "for_select",
                              on: {
                                click: function ($event) {
                                  _vm.nlawStop = 3
                                },
                              },
                            },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.nlaw.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.nlaw, "flag", $$v)
                                  },
                                  expression: "flags.nlaw.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.nlaw, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "ts",
                          style: {
                            height: _vm.flags.nlaw.children[index].flag
                              ? _vm.HelpAnimation.nlaw.children[index].hgt +
                                "px"
                              : "0px",
                            margin: _vm.flags.nlaw.children[index].flag
                              ? ""
                              : "0px",
                            opacity: _vm.flags.nlaw.children[index].flag
                              ? "1"
                              : "0",
                            padding: _vm.flags.nlaw.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _c("div", { staticClass: "_title" }, [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _c("span", [_vm._v(_vm._s(item.percent))]),
                            _vm.$route.query.is_create.toString() === "1"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "for_select",
                                    on: {
                                      click: function ($event) {
                                        _vm.nlawStop = 3
                                      },
                                    },
                                  },
                                  [
                                    _c("van-checkbox", {
                                      attrs: { "checked-color": "#F01C1C" },
                                      model: {
                                        value:
                                          _vm.flags.nlaw.children[index].flag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.flags.nlaw.children[index],
                                            "flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "flags.nlaw.children[index].flag",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._l(item.data, function (element, num) {
                            return _c(
                              "div",
                              {
                                key: num,
                                staticClass: "items",
                                style: {
                                  height: _vm.flags.nlaw.children[index]
                                    .children[num].flag
                                    ? _vm.HelpAnimation.nlaw.children[index]
                                        .children[num].hgt + "px"
                                    : "0px",
                                  margin: _vm.flags.nlaw.children[index]
                                    .children[num].flag
                                    ? ""
                                    : "0px",
                                },
                              },
                              [
                                _c("div", { staticClass: "Title" }, [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showLawDetail(
                                            item.law_id,
                                            element.title,
                                            element.text
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(element.title))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAppealDetail(
                                            "" + item.name + "" + element.title,
                                            element.offset,
                                            "entity"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(element.percent))]
                                  ),
                                  _vm.$route.query.is_create.toString() === "1"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "for_select",
                                          on: {
                                            click: function ($event) {
                                              _vm.nlawStop = 3
                                            },
                                          },
                                        },
                                        [
                                          _c("van-checkbox", {
                                            attrs: {
                                              "checked-color": "#F01C1C",
                                            },
                                            model: {
                                              value:
                                                _vm.flags.nlaw.children[index]
                                                  .children[num].flag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.flags.nlaw.children[index]
                                                    .children[num],
                                                  "flag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "flags.nlaw.children[index].children[num].flag",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    !element.text
                                      ? _c("van-loading")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                element.text.length > 0
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v(" " + _vm._s(element.text) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("nlaw")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.law.length > 0
              ? _c(
                  "div",
                  {
                    ref: "law",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.law.flag
                        ? _vm.HelpAnimation["law"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.law.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 辩论使用的法律法规 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.law.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.law, "flag", $$v)
                                  },
                                  expression: "flags.law.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.law, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "ts",
                          style: {
                            height: _vm.flags.law.children[index].flag
                              ? _vm.HelpAnimation.law.children[index].hgt + "px"
                              : "0px",
                            margin: _vm.flags.law.children[index].flag
                              ? ""
                              : "0px",
                            opacity: _vm.flags.law.children[index].flag
                              ? "1"
                              : "0",
                            padding: _vm.flags.law.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _c("div", { staticClass: "_title" }, [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _c("span", [_vm._v(_vm._s(item.percent))]),
                            _vm.$route.query.is_create.toString() === "1"
                              ? _c(
                                  "div",
                                  { staticClass: "for_select" },
                                  [
                                    _c("van-checkbox", {
                                      attrs: { "checked-color": "#F01C1C" },
                                      model: {
                                        value:
                                          _vm.flags.law.children[index].flag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.flags.law.children[index],
                                            "flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "flags.law.children[index].flag",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._l(item.data, function (element, num) {
                            return _c(
                              "div",
                              {
                                key: num,
                                staticClass: "items",
                                style: {
                                  height: _vm.flags.law.children[index]
                                    .children[num].flag
                                    ? _vm.HelpAnimation.law.children[index]
                                        .children[num].hgt + "px"
                                    : "0px",
                                  margin: _vm.flags.law.children[index]
                                    .children[num].flag
                                    ? ""
                                    : "0px",
                                },
                              },
                              [
                                _c("div", { staticClass: "Title" }, [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showLawDetail(
                                            item.law_id,
                                            element.title,
                                            element.text
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(element.title))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showAppealDetail(
                                            "" + item.name + "" + element.title,
                                            element.offset,
                                            "explore"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(element.percent))]
                                  ),
                                  _vm.$route.query.is_create.toString() === "1"
                                    ? _c(
                                        "div",
                                        { staticClass: "for_select" },
                                        [
                                          _c("van-checkbox", {
                                            attrs: {
                                              "checked-color": "#F01C1C",
                                            },
                                            model: {
                                              value:
                                                _vm.flags.law.children[index]
                                                  .children[num].flag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.flags.law.children[index]
                                                    .children[num],
                                                  "flag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "flags.law.children[index].children[num].flag",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    !element.text
                                      ? _c("van-loading")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                element.text.length > 0
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v(" " + _vm._s(element.text) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("law")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.focus.length > 0
              ? _c(
                  "div",
                  {
                    ref: "focus",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.focus.flag
                        ? _vm.HelpAnimation["focus"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.focus.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 争议焦点 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.focus.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.focus, "flag", $$v)
                                  },
                                  expression: "flags.focus.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.focus, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "focus_items",
                          style: {
                            height: _vm.flags.focus.children[index].flag
                              ? _vm.HelpAnimation["focus"].children[index].hgt +
                                "px"
                              : "0px",
                            opacity: _vm.flags.focus.children[index].flag
                              ? "1"
                              : "0",
                            overflow: "hidden",
                            margin: _vm.flags.focus.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _vm._l(item.case, function (_item, num) {
                            return _c(
                              "div",
                              {
                                key: num,
                                staticClass: "_title",
                                on: {
                                  click: function ($event) {
                                    return _vm.BeHot(
                                      "focus",
                                      index,
                                      num,
                                      item.case[0],
                                      _item.text
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_item.text) + " ")]
                            )
                          }),
                          _vm.$route.query.is_create.toString() === "1"
                            ? _c(
                                "div",
                                { staticClass: "for_select" },
                                [
                                  _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.flags.focus.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.focus.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.focus.children[index].flag",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "tip_line" }, [
                            _c("div", [
                              _c("i", { staticClass: "iconfont icon-fenxi" }),
                              _c("span", [_vm._v(_vm._s(item.percent))]),
                            ]),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.morefocus(item.case[0], index)
                                  },
                                },
                              },
                              [_vm._v(" 查看更多 ")]
                            ),
                          ]),
                        ],
                        2
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("focus")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.evt.length > 0
              ? _c(
                  "div",
                  {
                    ref: "evt",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.evt.flag
                        ? _vm.HelpAnimation["evt"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.evt.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 证据名称 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.evt.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.evt, "flag", $$v)
                                  },
                                  expression: "flags.evt.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.evt, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "focus_items",
                          style: {
                            height: _vm.flags.evt.children[index].flag
                              ? _vm.HelpAnimation["evt"].children[index].hgt +
                                "px"
                              : "0px",
                            opacity: _vm.flags.evt.children[index].flag
                              ? "1"
                              : "0",
                            overflow: "hidden",
                            margin: _vm.flags.evt.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _vm._l(item.case, function (_item, num) {
                            return _c(
                              "div",
                              {
                                key: num,
                                staticClass: "_title",
                                on: {
                                  click: function ($event) {
                                    return _vm.BeHot(
                                      "evt",
                                      index,
                                      num,
                                      item.case[0],
                                      _item.text
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_item.text) + " ")]
                            )
                          }),
                          _vm.$route.query.is_create.toString() === "1"
                            ? _c(
                                "div",
                                { staticClass: "for_select" },
                                [
                                  _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value: _vm.flags.evt.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.evt.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.evt.children[index].flag",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "tip_line" }, [
                            _c("div", [
                              _c("i", { staticClass: "iconfont icon-fenxi" }),
                              _c("span", [_vm._v(_vm._s(item.percent))]),
                            ]),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.moreevt(item.case[0], index)
                                  },
                                },
                              },
                              [_vm._v(" 查看更多 ")]
                            ),
                          ]),
                        ],
                        2
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("evt")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.deem.length > 0
              ? _c(
                  "div",
                  {
                    ref: "deem",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.deem.flag
                        ? _vm.HelpAnimation["deem"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.deem.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 本院认为 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value: _vm.flags.deem.flag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.flags.deem, "flag", $$v)
                                      },
                                      expression: "flags.deem.flag",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.deem, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "deem_item",
                          style: {
                            height: _vm.flags.deem.children[index].flag
                              ? _vm.HelpAnimation["deem"].children[index].hgt +
                                "px"
                              : "0px",
                            opacity: _vm.flags.deem.children[index].flag
                              ? "1"
                              : "0",
                            margin: _vm.flags.deem.children[index].flag
                              ? ""
                              : "0px",
                            padding: _vm.flags.deem.children[index].flag
                              ? ""
                              : "0px",
                            overflow: "hidden",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text",
                              style: {
                                "-webkit-line-clamp": item.flag ? "" : "4",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.GoDetail(
                                    item.doc_id,
                                    item.text,
                                    "deem",
                                    item.text
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.flags.deem.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.deem.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.deem.children[index].flag",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more",
                              style: {
                                right: item.text.length < 20 ? "0.8rem" : "",
                              },
                              on: {
                                click: function ($event) {
                                  item.flag = !item.flag
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.flag ? "收起" : "展开") + " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("deem")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.lawyer.length > 0
              ? _c(
                  "div",
                  {
                    ref: "lawyer",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.lawyer.flag
                        ? _vm.HelpAnimation["lawyer"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.lawyer.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 诉请裁判 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value: _vm.flags.lawyer.flag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.flags.lawyer, "flag", $$v)
                                      },
                                      expression: "flags.lawyer.flag",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.lawyer, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "lawyer_item",
                          style: {
                            height: _vm.flags.lawyer.children[index].flag
                              ? _vm.HelpAnimation["lawyer"].children[index]
                                  .hgt + "px"
                              : "0px",
                            opacity: _vm.flags.lawyer.children[index].flag
                              ? "1"
                              : "0",
                            margin: _vm.flags.lawyer.children[index].flag
                              ? ""
                              : "0",
                            padding: _vm.flags.lawyer.children[index].flag
                              ? ""
                              : "0",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _vm.$route.query.is_create.toString() === "1"
                                ? _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.flags.lawyer.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.lawyer.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.lawyer.children[index].flag",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(item.data, function (Item, numb) {
                            return _c(
                              "label",
                              { key: numb },
                              [
                                _c("div", { staticClass: "_title" }, [
                                  _vm._v(_vm._s(Item.title)),
                                ]),
                                _vm._l(Item.data, function (_item, num) {
                                  return _c(
                                    "span",
                                    {
                                      key: num,
                                      on: {
                                        click: function ($event) {
                                          return _vm.GoDetail(
                                            item.doc_id,
                                            _item,
                                            "lawyer",
                                            _item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "request" }, [
                                        _vm._v(_vm._s(_item)),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("lawyer")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.litigation.length > 0
              ? _c(
                  "div",
                  {
                    ref: "litigation",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.litigation.flag
                        ? _vm.HelpAnimation["litigation"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.litigation.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 诉讼时间 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.litigation.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.litigation, "flag", $$v)
                                  },
                                  expression: "flags.litigation.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.litigation, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "time_items",
                          style: {
                            height: _vm.flags.litigation.children[index].flag
                              ? _vm.HelpAnimation["litigation"].children[index]
                                  .hgt + "px"
                              : "0px",
                            margin: _vm.flags.litigation.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _c("div", { staticClass: "time_title" }, [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _vm.$route.query.is_create.toString() === "1"
                              ? _c(
                                  "div",
                                  { staticClass: "for_select" },
                                  [
                                    _c("van-checkbox", {
                                      attrs: { "checked-color": "#F01C1C" },
                                      model: {
                                        value:
                                          _vm.flags.litigation.children[index]
                                            .flag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.flags.litigation.children[
                                              index
                                            ],
                                            "flag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "flags.litigation.children[index].flag",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "vcharts" },
                            [
                              _c("VeHistogram", {
                                attrs: {
                                  tooltip: item.params.tooltip,
                                  "tooltip-visible": false,
                                  grid: item.params.grid,
                                  xAxis: item.params.xAxis,
                                  yAxis: item.params.yAxis,
                                  series: item.params.series,
                                  settings: item.params,
                                  colors: item.params.color,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.data.body.length > 0
              ? _c(
                  "div",
                  {
                    ref: "body",
                    staticClass: "lawyer",
                    style: {
                      overflow: "hidden",
                      width: "100%",
                      height: _vm.flags.body.flag
                        ? _vm.HelpAnimation["body"].hgt + "px"
                        : "0px",
                      opacity: _vm.flags.body.flag ? "1" : "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "text_title" }, [
                      _vm._v(" 主体判例 "),
                      _vm.$route.query.is_create.toString() === "1"
                        ? _c(
                            "div",
                            { staticClass: "for_select" },
                            [
                              _c("van-checkbox", {
                                attrs: { "checked-color": "#F01C1C" },
                                model: {
                                  value: _vm.flags.body.flag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.flags.body, "flag", $$v)
                                  },
                                  expression: "flags.body.flag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.data.body, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "body_item",
                          style: {
                            height: _vm.flags.body.children[index].flag
                              ? _vm.HelpAnimation["body"].children[index].hgt +
                                "px"
                              : "0px",
                            opacity: _vm.flags.body.children[index].flag
                              ? "1"
                              : "0",
                            margin: _vm.flags.body.children[index].flag
                              ? ""
                              : "0px",
                            padding: _vm.flags.body.children[index].flag
                              ? ""
                              : "0px",
                          },
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                          _vm.$route.query.is_create.toString() === "1"
                            ? _c(
                                "div",
                                { staticClass: "for_select" },
                                [
                                  _c("van-checkbox", {
                                    attrs: { "checked-color": "#F01C1C" },
                                    model: {
                                      value:
                                        _vm.flags.body.children[index].flag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.flags.body.children[index],
                                          "flag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "flags.body.children[index].flag",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm.$route.query.is_create.toString() === "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "next_page",
                            on: {
                              click: function ($event) {
                                return _vm.next_page("body")
                              },
                            },
                          },
                          [_vm._v(" 下一页 ")]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.$route.query.is_create.toString() === "1"
              ? _c(
                  "button",
                  {
                    staticClass: "submit",
                    attrs: { disabled: _vm.is_submit },
                    on: { click: _vm.submit },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.is_submit ? "正在提交报告" : "生成报告") +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showflag
              ? _c(
                  "div",
                  { staticClass: "load" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$route.query.is_create.toString() === "1"
                            ? "加载中"
                            : "正在生成报告中"
                        )
                      ),
                    ]),
                    _c("van-loading", { attrs: { type: "spinner" } }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.toTop
        ? _c(
            "div",
            {
              staticClass: "scrollToTop",
              on: {
                click: function ($event) {
                  _vm.$refs.create.scrollTop = 0
                },
              },
            },
            [_c("i", { staticClass: "iconfont icon-zhiding" })]
          )
        : _vm._e(),
      !_vm.$route.query.id
        ? _c(
            "div",
            { ref: "remove_list", staticClass: "remove_list" },
            [
              _c(
                "fb-header",
                {
                  attrs: { slot: "header", title: "删除列表" },
                  slot: "header",
                },
                [
                  _c("fb-button", {
                    attrs: {
                      slot: "left",
                      icon: "back",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.back },
                    slot: "left",
                  }),
                ],
                1
              ),
              _c("removelist", {
                attrs: {
                  view: _vm.HelpAnimation,
                  nlaw: _vm.flags.nlaw,
                  module: _vm.data,
                },
                model: {
                  value: _vm.flags,
                  callback: function ($$v) {
                    _vm.flags = $$v
                  },
                  expression: "flags",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("appeal-list", {
        ref: "appeal_detail",
        attrs: { options: _vm.appealOpt },
        on: {
          HideAppealDetail: _vm.HideAppealDetail,
          showDetail: _vm.changeId,
        },
      }),
      _vm.appealDetail
        ? _c(
            "div",
            { staticClass: "detail_area" },
            [
              _c("percent-detail", {
                attrs: {
                  id: _vm.detailshow.doc_id,
                  words: _vm.detailshow.words,
                  forword: _vm.detailshow.forword,
                  titleName: _vm.detailshow.name,
                },
                on: {
                  changeflag: function ($event) {
                    _vm.appealDetail = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.lawflag
        ? _c(
            "div",
            { staticClass: "detail_area" },
            [
              _c("law-detail", {
                attrs: {
                  id: _vm.lawDetail.law_id,
                  title: _vm.lawDetail.title,
                  catlog: _vm.lawDetail.catlog,
                  words: _vm.lawDetail.words,
                },
                on: {
                  showflag: function ($event) {
                    _vm.lawflag = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.haha
        ? _c(
            "div",
            { staticClass: "skyDrive" },
            [
              _c("skyDrive", {
                attrs: {
                  report: _vm.report,
                  listname: _vm.listname,
                  listid: _vm.listid,
                },
                on: { CancelCollect: _vm.close, getId: _vm.getId },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }