/* eslint-disable */
;(function(global, queue) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? (module.exports = queue())
    : typeof define === 'function' && define.amd
    ? define(queue)
    : ((global = global || window), (global.queue = queue()))
})(this, function() {
  /** ******   链表位置   *******/
  // 链表长度
  LinkList.prototype.length = 0

  // 链表当前元素
  LinkList.prototype.currentElement = null

  // 元素的起始位置
  LinkList.prototype.firstElement = null

  // 链表添加元素方法
  LinkList.prototype.Add = function(element) {
    if (this.length === 0) {
      // 如果长度为0则不往下添加元素
      this.firstElement = {
        value: element,
        next: null
      }
      this.currentElement = this.firstElement
      this.length++
      return
    }
    this.currentElement.next = {
      value: element,
      next: null
    }
    this.currentElement = this.currentElement.next
    this.length++
  }

  // 链表通过索引获取元素
  LinkList.prototype.get = function(index) {
    return this.getElement(index).value
  }

  // 获取链表索引对象
  LinkList.prototype.getElement = function(index) {
    if (index >= this.length) {
      throw '索引越界'
    }
    var i = 0
    var element = this.firstElement
    while (i < index) {
      if (!element.next) {
        break
      }
      element = element.next
      i++
    }
    return element
  }

  // 链表删除方法
  LinkList.prototype.remove = function() {
    // 可能参数
    /* 两个参数 Number(删除的位置)
     *三个参数 Number(删除的起始位置) | Number(删除的结束位置)
     */
    var before = this.firstElement
    var after = this.firstElement
    var i = 0
    if (arguments.length === 0) {
      this.firstElement = null
      this.currentElement = null
      this.length = 0
    } else if (arguments.length === 1) {
      if (typeof arguments[0] !== 'number') {
        throw '删除参数有误:一个参数时应保持数字类型'
      }
      if (arguments[0] >= this.length) {
        throw '索引越界'
      }
      // 当删除的索引为最开始的地方时
      this.length--
      if (arguments[0] === 0) {
        this.firstElement = this.firstElement.next
        return
      }
      while (i < arguments[0] - 1) {
        before = before.next
        i++
      }
      before.next = before.next.next
      if (i === this.length - 1) {
        this.currentElement = before
      }
    } else if (arguments.length === 2) {
      if (
        typeof arguments[0] !== 'number' ||
        typeof arguments[1] !== 'number'
      ) {
        throw '删除参数有误:两个参数时应保持数字类型'
      }
      if (arguments[0] >= this.length) {
        throw '索引越界'
      }
      var start = arguments[0] < 0 ? 0 : arguments[0]
      var end = arguments[1] > this.length ? this.length : arguments[1]
      if (start === 0 && end === this.length) {
        this.remove()
        return
      }
      while (i < end) {
        if (i < start - 1) {
          before = before.next
        }
        if (i < end) {
          after = after.next
        }
        i++
      }
      if (start === 0) {
        this.currentElement = after
      } else {
        before.next = after
      }
      this.length -= end - start
    } else {
      throw '删除参数有误'
    }
  }

  // 链表插入方法
  LinkList.prototype.Insert = function(index, flag) {
    // index为起始位置,flag指示添加位置...后面的参数全部为需要插入的元素
    if (
      typeof arguments[0] !== 'number' ||
      typeof arguments[1] !== 'boolean' ||
      arguments.length < 3
    ) {
      throw '插入有误:一个参数时应保持数字类型,第二个参数为布尔类型且参数数量小于3'
    } else if (index >= this.length || index < 0) {
      throw '索引超出数组界限'
    }
    var i = 0
    var element = this.getElement(index)
    var prev = index - 1 > -1 ? this.getElement(index - 1) : this.firstElement
    var next = element.next
    var length = 0
    var items = {
      value: arguments[2],
      next: {}
    }
    var end = items
    length++
    var _wait_ = items.next
    //把要添加的元素串起来
    for (var i = 3; i < arguments.length; i++) {
      _wait_.value = arguments[i]
      if (i === arguments.length - 1) {
        end = _wait_
        break
      } else {
        _wait_.next = {}
      }
      _wait_ = _wait_.next
      length++
    }

    if (flag) {
      if (!next) {
        this.currentElement.next = items
        end.next = null
      } else {
        prev.next = items
        end.next = next
      }
    } else {
      if (prev === this.firstElement) {
        end.next = this.firstElement
        this.firstElement = items
      } else {
        prev.next = items
        end.next = next
      }
    }
    this.length += length
  }

  // 链表修改值的方法
  LinkList.prototype.SetVal = function(index, val) {
    if (index >= this.length || index < 0) {
      throw '索引越界'
    }
    var i = 0
    var element = this.firstElement
    while (i < index) {
      element = element.next
      i++
    }
    element.value = val
  }

  LinkList.prototype.__pop__ = function() {
    var element = this.firstElement
    this.firstElement = this.firstElement.next
    this.length--
    return element.value
  }

  /* 单向链表 */
  function LinkList() {}
  /** ******   链表位置   *******/

  /** ******  以下是队列接入  *********/
  queue.prototype._t = false

  queue.prototype._current = null

  queue.prototype.Add = function() {
    this._reslove(arguments)
    if (this.list.length > 1 && !this._t) {
      this._current = this._reslovereq(this.list.__pop__())
      this._current = this._reslovereq(this.list.__pop__())
      this._t = true
    }
  }

  queue.prototype.list = new LinkList()

  function queue(fn, callback) {
    this._reslove = fn
    this._reslovereq = callback
  }

  queue.prototype.destroy = function() {
    this.list.remove()
    this.t = false
    console.warn('队列已清空')
  }

  return new queue(reslove_element, reslove_req)

  /*********************************/

  function reslove_element() {
    // 外置处理新元素函数
    var _params = Array.prototype.slice.call(arguments[0])
    var axios = _params[0]
    var params = _params[1]
    var callback = _params[2]
    var _self = this
    var cancelobj = axios.CancelToken.source()
    params.cancelToken = cancelobj.token // 生成取消Token
    var _Ios = {
      axios: axios,
      cancelobj: cancelobj,
      params: params,
      callback: function(res) {
        callback(res)
        setTimeout(function() {
          if (_self.list.length > 0 && _self._t) {
            _self._reslovereq(_self.list.__pop__())
          } else {
            _self._t = false
          }
        }, 3000)
      }
    }
    this.list.Add(_Ios)
  }

  function reslove_req(params) {
    // 外置处理请求函数
    var axios = params.axios
    var _params = params.params
    var callback = params.callback
    var _self = this
    axios(_params)
      .then(callback)
      .catch(function() {
        _self.Add(axios, _params, callback)
        if (_self.list.length > 0) {
          _self._reslovereq(_self.list.__pop__())
        } else {
          _self._t = false
        }
      })
    return params.cancelobj
  }
})

/* eslint-disable */
