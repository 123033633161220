<template>
  <div class='cachelist'
       v-if="module&&control">
    <!-- 案情描述 -->
    <div id="ms"
         :style="{'overflow':'hidden','width':'100%','height':!control.ms?(view['ms']+'px'):'0px','opacity':!control.ms?'1':'0'}">
      <div class="text_title">
        案情描述
        <div class="opc_text">accident description</div>
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.ms"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="text_title_content">
        {{$route.query.is_create.toString()==='1'?this.$store.state.ana.caseName:params.caseName}}
      </div>
    </div>
    <!-- 相似判例 -->
    <div class="appeal"
         ref="case"
         v-if="module.case.length > 0&& parentFlags['case']">
      <div class="appeal_title">
        相似判例
        <div class="for_select"
             v-if="!control.case.flag">
          <van-checkbox v-model="control.case.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('case')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="des_item"
           v-for="(item,index) in module.case"
           v-show="!control.case.children[index].flag"
           :key="index"
           :div="index">
        <div class="_title">{{item.case_name}}</div>
        <div class="for_select_child"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.case.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="bottom_text">{{item.court_name+' / '+item.pub_date}}</div>
      </div>
    </div>
    <!-- 实体法 -->
    <div ref="nlaw"
         class="lawyer"
         v-if="(module.nlaw.length > 0 && nlaw.flag) || !control.nlaw.flag">
      <div class="text_title">
        辩论使用的法律法规
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.nlaw.flag"
                        v-if="!control.nlaw.flag"
                        checked-color="#F01C1C">
          </van-checkbox>
        </div>
      </div>
      <div class="ts"
           v-for="(item,index) in module.nlaw"
           v-show="!control.nlaw.children[index].flag || nlaw.nums.indexOf(index) > -1"
           :key="index">
        <!-- 总标题 -->
        <div class="_title">
          <span>{{item.name}}</span>
          <span>{{item.percent}}</span>
          <div class="for_select"
               v-if="$route.query.is_create.toString()==='1'">
            <van-checkbox v-model="control.nlaw.children[index].flag"
                          v-if="!control.nlaw.children[index].flag"
                          checked-color="#F01C1C">
            </van-checkbox>
          </div>
        </div>
        <!-- 内容 -->
        <div class="items"
             v-for="(element,num) in item.data"
             v-show="!control.nlaw.children[index].children[num].flag"
             :key="num">
          <div class="Title">
            <span>{{element.title}}</span>
            <span>{{element.percent}}</span>
            <div class="for_select"
                 v-if="$route.query.is_create.toString()==='1'">
              <van-checkbox v-model="control.nlaw.children[index].children[num].flag"
                            checked-color="#F01C1C">
              </van-checkbox>
            </div>
          </div>
          <div style="display:flex;width:100%;justify-content:center;">
            <van-loading v-if="!element.text" />
          </div>
          <div class="content"
               v-if="element.text.length > 0">
            {{element.text}}
          </div>
        </div>
      </div>
    </div>
    <!-- 探索法 -->
    <div ref="law"
         class="lawyer"
         v-if="(module.law.length > 0 && law.flag) || !control.law.flag">
      <div class="text_title">
        辩论使用的法律法规
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.law.flag"
                        v-if="!control.law.flag"
                        checked-color="#F01C1C">
          </van-checkbox>
        </div>
      </div>
      <div class="ts"
           v-for="(item,index) in module.law"
           v-show="!control.law.children[index].flag || law.nums.indexOf(index) > -1"
           :key="index">
        <!-- 总标题 -->
        <div class="_title">
          <span>{{item.name}}</span>
          <span>{{item.percent}}</span>
          <div class="for_select"
               v-if="$route.query.is_create.toString()==='1'">
            <van-checkbox v-model="control.law.children[index].flag"
                          v-if="!control.law.children[index].flag"
                          checked-color="#F01C1C">
            </van-checkbox>
          </div>
        </div>
        <!-- 内容 -->
        <div class="items"
             v-for="(element,num) in item.data"
             v-show="!control.law.children[index].children[num].flag"
             :key="num">
          <div class="Title">
            <span>{{element.title}}</span>
            <span>{{element.percent}}</span>
            <div class="for_select"
                 v-if="$route.query.is_create.toString()==='1'">
              <van-checkbox v-model="control.law.children[index].children[num].flag"
                            checked-color="#F01C1C">
              </van-checkbox>
            </div>
          </div>
          <div style="display:flex;width:100%;justify-content:center;">
            <van-loading v-if="!element.text" />
          </div>
          <div class="content"
               v-if="element.text.length > 0">
            {{element.text}}
          </div>
        </div>
      </div>
    </div>
    <!-- 争议焦点 -->
    <div ref="focus"
         class="lawyer"
         v-if="module.focus.length > 0&&parentFlags['focus']">
      <div class="text_title">
        争议焦点
        <div class="for_select"
             @click="stopUpdate = true"
             v-if="!control.focus.flag">
          <van-checkbox v-model="control.focus.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('focus')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="focus_items"
           v-for="(item,index) in module.focus"
           v-show="!control.focus.children[index].flag"
           :key="index">
        <div class='_title'
             v-for="(_item,num) in item.title"
             :key="num">{{_item}}</div>
        <div class="for_select"
             @click="stopUpdate = true"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.focus.children[index].flag"
                        v-if="!control.focus.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="tip_line">
          <div>
            <i class="iconfont icon-fenxi"></i>
            <span>{{item.percent}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 证据名称 -->
    <div ref="evt"
         class="lawyer"
         v-if="module.evt.length > 0&&parentFlags['evt']">
      <div class="text_title">
        证据名称
        <div class="for_select"
             @click="stopUpdate=true"
             v-if="!control.evt.flag">
          <van-checkbox v-model="control.evt.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('evt')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="focus_items"
           v-for="(item,index) in module.evt"
           v-show="!control.evt.children[index].flag"
           :key="index">
        <div class='_title'
             v-for="(_item,num) in item.title"
             :key="num">{{_item}}</div>
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.evt.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="tip_line">
          <div>
            <i class="iconfont icon-fenxi"></i>
            <span>{{item.percent}}</span>
          </div>
        </div>
      </div>
    </div>
    <!--诉请裁判额外信息-->
    <div class="lawyer"
         ref="lawyer"
         v-if="module.lawyer.length>0 && parentFlags.lawyer">
      <div class="text_title">
        诉请裁判
        <div class="for_select"
             v-if="!control.lawyer.flag">
          <van-checkbox v-model="control.lawyer.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('lawyer')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="lawyer_item"
           v-for="(item,index) in module.lawyer"
           v-show="!control.lawyer.children[index].flag"
           :key="index">
        <div class="for_select"
             @click="stopUpdate = true">
          <van-checkbox v-model="control.lawyer.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="text_area"
             v-if="item.原审诉讼请求.length > 0">
          <div class="_title">原审诉讼请求</div>
        </div>
        <span v-if="item.原审诉讼请求.length > 0">
          <ul class="appeal_title">
            <li v-for="(_item,num) in item.原审诉讼请求"
                :key="num">{{num + 1}} . {{_item}}</li>
          </ul>
        </span>
        <div class="_title"
             v-if="item.上诉请求.length > 0">上诉请求</div>
        <span v-for="(_item,num) in item.上诉请求"
              :key="num">
          <div class="request">{{num + 1}} . {{_item}}</div>
        </span>
        <div class="_title"
             v-if="item.原审裁判结果.length > 0">原审裁判结果</div>
        <span v-if="item.原审裁判结果.length > 0">
          <div class="deem"
               v-for="(_item,num) in item.原审裁判结果"
               :key="num">{{num + 1}} . {{_item}}</div>
        </span>
        <div class="_title"
             v-if="item.法院判决.length > 0">法院判决</div>
        <span v-if="item.法院判决.length > 0">
          <div class="deem"
               v-for="(_item,num) in item.法院判决"
               :key="num">{{num + 1}} . {{_item}}</div>
        </span>
      </div>
    </div>
    <!-- 本院认为 -->
    <div class="lawyer"
         ref="deem"
         v-if="module.deem.length > 0 && parentFlags.deem">
      <div class="text_title">
        本院认为
        <div class="for_select"
             v-if="!control.deem.flag">
          <van-checkbox v-model="control.deem.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('deem')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="deem_item"
           v-for="(item,index) in module.deem"
           v-show="!control.deem.children[index].flag"
           :key="index">
        <div class="text"
             :style="{'-webkit-line-clamp':item.flag?'':'4'}">{{item.text}}</div>
        <div class="for_select"
             @click="stopUpdate = true">
          <van-checkbox v-model="control.deem.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="more"
             :style="{'right':item.text.length < 20?'0.8rem':''}"
             @click="item.flag = !item.flag">{{item.flag ? '收起':'展开'}}</div>

      </div>
    </div>
    <!-- 诉讼时间 -->
    <div class="lawyer"
         ref="litigation"
         v-if="module.litigation.length > 0 && parentFlags.litigation">
      <div class="text_title">
        诉讼时间
        <div class="for_select"
             v-if="!control.litigation.flag">
          <van-checkbox v-model="control.litigation.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('litigation')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="time_items"
           v-for="(item,index) in module.litigation"
           v-show="!control.litigation.children[index].flag"
           :key="index">
        <div class="time_title">
          <span>{{item.name}}</span>
          <div class="for_select"
               v-if="!control.litigation.children[index].flag">
            <van-checkbox v-model="control.litigation.children[index].flag"
                          checked-color="#F01C1C"></van-checkbox>
          </div>
        </div>
        <div class="vcharts">
          <VeHistogram :tooltip="item.params.tooltip"
                       :tooltip-visible='false'
                       :grid="item.params.grid"
                       :xAxis="item.params.xAxis"
                       :yAxis="item.params.yAxis"
                       :series="item.params.series"
                       :settings="item.params"
                       :colors="['#4B9EFB','#2DC1E7','#C0DCFB']"></VeHistogram>
        </div>
      </div>
    </div>
    <!-- 主体判例 -->
    <div ref="body"
         class="lawyer"
         v-if="module.body.length > 0 && parentFlags.body">

      <div class="text_title">
        主体判例
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.body.flag"
                        v-if="!control.body.flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
        <div class="for_select"
             @click.capture="BecomeTrue('body')"
             v-else>
          <van-checkbox v-model="InvaildFlag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
      <div class="body_item"
           v-for="(item,index) in module.body"
           v-show="!control.body.children[index].flag"
           :key="index">
        <div class="title">{{item}}</div>
        <div class="for_select"
             v-if="$route.query.is_create.toString()==='1'">
          <van-checkbox v-model="control.body.children[index].flag"
                        checked-color="#F01C1C"></van-checkbox>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import VeHistogram from 'v-charts/lib/histogram.common'
export default {
  props: ['control', 'module', 'view'],
  model: {
    prop: 'control',
    event: 'changeMVC'
  },
  components: {
    VeHistogram
  },
  data () {
    return {
      InvaildFlag: false,
      parentFlags: {

      },
      stopUpdate: false,
      nlaw: {
        flag: false,
        nums: []
      },
      law: {
        flag: false,
        nums: []
      },
      flags: {
        case: null,
        focus: null,
        evt: null,
        body: null,
        deem: null,
        lawyer: null,
        litigation: null
      }
    }
  },
  methods: {
    findIndexOf (obj) {
      let _all = true
      let index = -1
      for (let i = 0; i < obj.children.length; i++) {
        if (!obj.children[i].flag) {
          index = i
        } else {
          _all = false
        }
      }
      return {
        index: index,
        flag: _all
      }
    },
    BecomeTrue (name) {
      for (let i = 0; i < this.control[name].children.length; i++) {
        this.$set(this.control[name].children[i], 'flag', true)
      }
      this.$set(this.control[name], 'flag', true)
      setTimeout(() => {
        this.InvaildFlag = false
      })
    }
  },
  watch: {
    module: {
      immediate: true,
      deep: true,
      handler (val) { }
    },
    control: {
      deep: true,
      handler (val) {
        for (const item in val) {
          if (item === 'case' || item === 'focus' || item === 'evt' || item === 'body' || item === 'deem' || item === 'lawyer' || item === 'litigation') {
            if (val[item].flag) {
              if (val[item].flag === this.flags[item]) {
                for (let i = 0; i < val[item].children.length; i++) {
                  if (!val[item].children[i].flag) {
                    this.$set(this.parentFlags, item, true)
                    break
                  }
                  if (i === val[item].children.length - 1) {
                    this.$set(this.parentFlags, item, false)
                  }
                }
              } else {
                for (let i = 0; i < val[item].children.length; i++) {
                  val[item].children[i].flag = true
                }
              }
            } else {
              if (val[item].flag === this.flags[item]) {
                for (let i = 0; i < val[item].children.length; i++) {
                  if (!val[item].children[i].flag) {
                    continue
                  } else {
                    val[item].flag = true
                  }
                  if (i === val[item].children.length - 1) {
                    this.$set(this.parentFlags, item, false)
                  }
                }
              } else {
                for (let i = 0; i < val[item].children.length; i++) {
                  val[item].children[i].flag = false
                }
                this.$set(this.parentFlags, item, true)
              }
            }
            this.flags[item] = val[item].flag
          } else if (item === 'nlaw') {
            this.parentFlags[item] = val[item]
            if (!val[item].flag) {
              this.parentFlags.nlaw.children.forEach((item, index) => {
                this.$set(this.parentFlags.nlaw.children[index], 'flag', false)
                this.parentFlags.nlaw.children[index].children.forEach((_item, num) => {
                  this.$set(this.parentFlags.nlaw.children[index].children[num], 'flag', false)
                })
              })
            } else if (val[item].flag && !this.nlaw._flag) {
              this.parentFlags.nlaw.children.forEach((item, index) => {
                this.$set(this.parentFlags.nlaw.children[index], 'flag', true)
                this.parentFlags.nlaw.children[index].children.forEach((_item, num) => {
                  this.$set(this.parentFlags.nlaw.children[index].children[num], 'flag', true)
                })
              })
            } else {
              let check = true
              this.parentFlags.nlaw.children.forEach((item, index) => {
                if (item.flag && this.findIndexOf(item).index > -1 && !this.findIndexOf(item).flag) {
                  this.nlaw.nums.push(index)
                } else if (!item.flag) {
                  this.$set(this.parentFlags.nlaw.children[index], 'flag', false)
                  this.nlaw.flag = true
                  check = false
                  this.parentFlags.nlaw.children[index].children.forEach((item, num) => {
                    this.$set(this.parentFlags.nlaw.children[index].children[num], 'flag', false)
                  })
                } else {
                  while (this.nlaw.nums.indexOf(index) > -1) {
                    this.nlaw.nums.splice(index, 1)
                  }
                  this.parentFlags.nlaw.children[index].children.forEach((item, num) => {
                    this.$set(this.parentFlags.nlaw.children[index].children[num], 'flag', true)
                  })
                }
              })
              if (check) {
                this.nlaw.flag = false
              }
              this.parentFlags.nlaw.children.forEach((item, index) => {
                this.parentFlags.nlaw.children[index].children.forEach((item, num) => {
                  if (!item.flag) {
                    this.nlaw.nums.push(index)
                    this.nlaw.flag = true
                    this.$set(this.parentFlags.nlaw.children[index].children[num], 'flag', false)
                  }
                })
              })
            }
            this.nlaw._flag = val[item].flag
          } else if (item === 'law') {
            this.parentFlags[item] = val[item]
            if (!val[item].flag) {
              this.parentFlags.law.children.forEach((item, index) => {
                this.$set(this.parentFlags.law.children[index], 'flag', false)
                this.parentFlags.law.children[index].children.forEach((_item, num) => {
                  this.$set(this.parentFlags.law.children[index].children[num], 'flag', false)
                })
              })
            } else if (val[item].flag && !this.law._flag) {
              this.parentFlags.law.children.forEach((item, index) => {
                this.$set(this.parentFlags.law.children[index], 'flag', true)
                this.parentFlags.law.children[index].children.forEach((_item, num) => {
                  this.$set(this.parentFlags.law.children[index].children[num], 'flag', true)
                })
              })
            } else {
              let check = true
              this.parentFlags.law.children.forEach((item, index) => {
                if (item.flag && this.findIndexOf(item).index > -1 && !this.findIndexOf(item).flag) {
                  this.law.nums.push(index)
                } else if (!item.flag) {
                  this.$set(this.parentFlags.law.children[index], 'flag', false)
                  this.law.flag = true
                  check = false
                  this.parentFlags.law.children[index].children.forEach((item, num) => {
                    this.$set(this.parentFlags.law.children[index].children[num], 'flag', false)
                  })
                } else {
                  while (this.law.nums.indexOf(index) > -1) {
                    this.law.nums.splice(index, 1)
                  }
                  this.parentFlags.law.children[index].children.forEach((item, num) => {
                    this.$set(this.parentFlags.law.children[index].children[num], 'flag', true)
                  })
                }
              })
              if (check) {
                this.law.flag = false
              }
              this.parentFlags.law.children.forEach((item, index) => {
                this.parentFlags.law.children[index].children.forEach((item, num) => {
                  if (!item.flag) {
                    this.law.nums.push(index)
                    this.law.flag = true
                    this.$set(this.parentFlags.law.children[index].children[num], 'flag', false)
                  }
                })
              })
            }
            this.law._flag = val[item].flag
          } else {
            this.$set(this.parentFlags, item, !val[item])
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
*
  transition all 0.7s
.cachelist
  height calc(100% - 1.173333rem)
  overflow auto
  box-sizing border-box
.title
  font-size 36px
  color #F01C1C
  font-weight 600
  text-align center
  margin 30px 0px 30px 0px
.name_area
  color #333333
  font-size 15px
  text-align right
  margin-bottom 20px
  span:nth-child(1)
    margin-right 20px
  span:nth-child(2)
    margin-right 5px
.think
  div:nth-child(1)
    width 100%
    color #333333
    font-size 16px
    font-weight 600
  .xiangfa
    margin-top 10px
    width 100%
    resize none
    font-size 16px
    font-weight 600
    white-space wrap
    word-break break-all
    min-height 20px
    border none
    outline none
.text_title
  color #FC5744
  font-size 25px
  font-weight bold
  text-align left
  margin 38px 0px 38px 0px
  position relative
  .opc_text
    position absolute
    top 10px
    left 0px
    text-align left
    opacity 0.1
    color #F01C1C
    width 100%
  .for_select
    position absolute
    display inline-block
    right 5px
    top 3px
    z-index 10
.text_title_content
  font-size 16px
  line-height 22px
  color #333333
  overflow hidden
  font-weight 600
.all
  margin-top 38px
  overflow hidden
  span
    font-size 16px
    color #F01C1C
    align-self center
    vertical-align middle
    float right
    height 100%
    line-height 20px
    font-weight bold
  div
    align-self center
    float right
    vertical-align middle
    margin-left 3px
.space
  clear both
  width 100%
  display none
  color #333333
  font-size 16px
  font-weight bold
  margin 10px 0px 50px 0px
.appeal
  margin-top 50px
  width 100%
  overflow hidden
  .appeal_title
    width 100%
    font-size 25px
    padding 0px
    text-align left
    font-weight bold
    color #fc5744
    position relative
    margin-bottom 20px
    .for_select
      width 30px
      height 30px
      position absolute
      right 0px
      top -5px
  .des_item
    width 100%
    overflow hidden
    margin-bottom 20px
    position relative
    ._title
      font-size 16px
      color #000000
      font-weight 600
      max-width 92%
    .for_select_child
      position absolute
      top 0px
      right 0px
    .bottom_text
      width 100%
      margin-top 10px
      color #CCCCCC
      font-size 13px
.load
  width 200px
  display flex
  justify-content center
  position absolute
  top 0px
  left calc(50% - 100px)
  top 6px
  span
    align-self center
    margin-right 10px
    color #333333
    font-size 16px
.remove_list
  width 100%
  height 100%
  position absolute
  top 0px
  transition all 0.7s
.submit
  display block
  width 43%
  height 35px
  font-size 16px
  color white
  margin 20px auto
  text-align center
  background-color #FC6F53
  line-height 35px
  float left
  border-radius 5px
.load
  width 200px
  display flex
  justify-content center
  position absolute
  top 0px
  left calc(50% - 100px)
  top 6px
  span
    align-self center
    margin-right 10px
    color #333333
    font-size 16px
.ts
  width 100%
  border 1px solid #cccccc
  overflow hidden
  border-radius 5px
  padding 10px
  box-sizing border-box
  font-size 16px
  font-weight 600
  box-shadow 0px 0px 10px #cccccc
  margin-bottom 20px
  ._title
    width 100%
    position relative
    display flex
    justify-content space-between
    span:nth-child(1)
      font-weight 600
    span:nth-child(2)
      display inline-block
      margin-right 40px
      font-size 14px
      font-weight normal
      align-self center
    .for_select
      position absolute
      right 0px
      top -3px
  .items
    margin-top 25px
    overflow hidden
    .Title
      font-weight normal
      position relative
      display flex
      justify-content space-between
      font-size 15px
      span:nth-child(1)
        color #333333
      span:nth-child(2)
        color #999999
        display inline-block
        margin-right 30px
        font-size 13px
        align-self center
      .for_select
        position absolute
        right 0px
    .content
      width 100%
      min-height 70px
      overflow hidden
      margin-top 19px
      color #666666
      font-size 15px
      font-weight normal
.lawyer
  width 100%
  .time_title
    width 95%
    margin 0px auto
    position relative
    .for_select
      position absolute
      right 10px
      top 5px
      right 5px
    span
      font-size 15px
      color #333333
  .time_items
    width 100%
    margin-top 20px
    height 350px
    overflow hidden
    .vcharts
      margin-top 15px
      width 100%
  .body_item
    width 100%
    overflow hidden
    padding 10px
    box-shadow 0px 0px 5px #cccccc
    position relative
    margin-bottom 20px
    .for_select
      position absolute
      right 10px
      top 10px
    .title
      color #666666
      font-size 15px
      text-align left
      margin 0px
  .deem_item
    width 100%
    box-shadow 0px 0px 5px #cccccc
    overflow hidden
    box-sizing border-box
    padding 10px
    position relative
    margin-bottom 20px
    .text
      color #666666
      font-size 15px
      display -webkit-box
      -webkit-box-orient vertical
      overflow hidden
      width 92%
    .for_select
      position absolute
      right 10px
      top 10px
    .more
      font-size 14px
      color #4B9EFB
      position absolute
      bottom 10px
      right 12px
  .lawyer_item
    width calc(100% - 4px)
    margin 0px auto
    border-radius 10px
    box-shadow 0px 0px 5px #cccccc
    overflow hidden
    padding 10px
    box-sizing border-box
    margin-bottom 20px
    position relative
    .for_select
      position absolute
      right 10px
      top 5px
    .text_area
      width 100%
      font-size 15px
      color #333333
      display flex
      justify-content space-between
    .appeal_title
      margin-top 20px
      color #666666
      font-size 15px
      margin-bottom 15px
    ._title
      color #333333
      font-size 15px
    .request
      color #666666
      font-size 15px
      margin-top 19px
      margin-bottom 20px
    .deem
      width 100%
      color #666666
      margin-top 20px
      font-size 15px
  .focus_items
    width 100%
    margin-bottom 20px
    position relative
    overflo hidden
    ._title
      color #666666
      font-size 15px
      text-align left
      font-weight normal
      width 90%
      float left
    .for_select
      width 10%
      text-align right
      overflow hidden
      float right
      position absolute
      right 5px
    .tip_line
      width 100%
      display flex
      justify-content space-between
      div
        color #999999
        margin-top 10px
      div:nth-child(1)
        span, i
          font-size 15px
      div:nth-child(2)
        font-size 15px
        color #999999
.next_page
  width 100%
  text-align center
  color #666666
  font-weight normal
  font-size 15px
</style>
