export default {
  data() {
    return {
      scrollTargetPositionY: 0,
    }
  },
  methods: {
    showBottom() {
      // console.log('show')
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showBottomTab('display')
        } else if (u === 'fb-iOS-Webview') {
          // eslint-disable-next-line
          // window.webkit.messageHandlers.showCamera.postMessage({ caseId, pId })
        } else if (u === 'fb-flutter-Webview') {
          console.log('fb-flutter-Webview')
          // eslint-disable-next-line
          // window.webkit.messageHandlers.showCamera.postMessage({ caseId, pId })
        }
      } catch (err) {
        console.log(err)
      }
    },
    hiddenBottom() {
      // console.log('hidden')
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showBottomTab('hide')
        } else if (u === 'fb-iOS-Webview') {
          // eslint-disable-next-line
          // window.webkit.messageHandlers.showCamera.postMessage({ caseId, pId })
        }
      } catch (err) {
        console.log(err)
      }
    },
    taggleBottomScroll(e) {
      if (e.target.scrollTop) {
        // this.hiddenBottom()
        this.scrollTargetPositionY = e.target.scrollTop
      } else {
        // this.showBottom()
        this.scrollTargetPositionY = e.target.scrollTop
      }
    },
  },
}
