<template>
  <div class="ctn">
    <div class="edit" ref="edit">
      <div class="create" ref="create">
        <fb-header
          slot="header"
          v-if="$route.query.is_create.toString() === '1'"
          :title="''"
        >
          <fb-button
            slot="left"
            icon="back"
            size="small"
            @click="$router.go(-1)"
            type="primary"
          >
          </fb-button>
          <template slot="right">
            <span class="share" @click="collection">收藏</span>
            <span class="share" @click="download">下载</span>
            <span class="share" @click="submit">分享</span>
          </template>
        </fb-header>
        <div class="title">案情分析报告</div>
        <div class="name_area">
          <span>{{
            $route.query.is_create.toString() === '1'
              ? this.$store.state.user.userInfo.nickName
              : params.name
          }}</span>
          <span v-if="$route.query.is_create.toString() === '1'"
            >{{ new Date().getFullYear() }}.{{ new Date().getMonth() + 1 }}.{{
              new Date().getDate()
            }}</span
          >
          <span v-else>{{ params.date }}</span>
        </div>
        <div class="think">
          <div>
            根据您提供的案情，通过大数据人工智能技术处理，找到相似判例，并进行专业分析，得出如下结论：
          </div>
          <div
            ref="think"
            class="xiangfa"
            :contenteditable="$route.query.is_create.toString() === '1'"
          >
            {{
              $route.query.is_create.toString() === '1'
                ? '在此输入想法'
                : params.think
            }}
          </div>
        </div>
        <!-- 案情描述 -->
        <div
          id="ms"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.ms ? HelpAnimation['ms'] + 'px' : '0px',
            opacity: flags.ms ? '1' : '0'
          }"
        >
          <div class="text_title">
            案情描述
            <div class="opc_text">accident description</div>
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.ms"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div class="text_title_content">
            {{
              $route.query.is_create.toString() === '1'
                ? this.$store.state.ana.caseName
                : params.caseName
            }}
          </div>
        </div>
        <div class="space">
          根据您提供的案情，通过大数据人工智能技术处理，找到相似判例，并进行专业分析，得出如下结论：
        </div>
        <!-- 相似判例 -->
        <div
          class="appeal"
          ref="case"
          :style="{
            height: flags.case.flag ? HelpAnimation['case'].hgt + 'px' : '0px',
            opacity: flags.case.flag ? '1' : '0',
            margin: flags.case.flag ? '' : '0px'
          }"
          v-if="data.case.length > 0"
        >
          <div class="appeal_title">
            相似判例
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.case.flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="des_item"
            v-for="(item, index) in data.case"
            :style="{
              overflow: 'hidden',
              height: flags.case.children[index].flag
                ? HelpAnimation['case'].children[index].hgt + 'px'
                : '0px',
              margin: flags.case.children[index].flag ? '' : '0px',
              opacity: flags.case.children[index].flag ? '1' : '0'
            }"
            :key="index"
            :div="index"
          >
            <div
              class="_title"
              @click="GoDetail(item.doc_id, item.case_name, 'case')"
            >
              {{ item.case_name }}
            </div>
            <div
              class="for_select_child"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.case.children[index].flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
            <div class="bottom_text">
              {{ item.court_name + ' / ' + item.pub_date }}
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('case')"
          >
            下一页
          </div>
        </div>
        <!-- 实体法 -->
        <div
          ref="nlaw"
          class="lawyer"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.nlaw.flag ? HelpAnimation['nlaw'].hgt + 'px' : '0px',
            opacity: flags.nlaw.flag ? '1' : '0'
          }"
          v-if="data.nlaw.length > 0"
        >
          <div class="text_title">
            辩论使用的法律法规
            <div
              class="for_select"
              @click="nlawStop = 3"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox v-model="flags.nlaw.flag" checked-color="#F01C1C">
              </van-checkbox>
            </div>
          </div>
          <div
            class="ts"
            v-for="(item, index) in data.nlaw"
            :style="{
              height: flags.nlaw.children[index].flag
                ? HelpAnimation.nlaw.children[index].hgt + 'px'
                : '0px',
              margin: flags.nlaw.children[index].flag ? '' : '0px',
              opacity: flags.nlaw.children[index].flag ? '1' : '0',
              padding: flags.nlaw.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <!-- 总标题 -->
            <div class="_title">
              <span>{{ item.name }}</span>
              <span>{{ item.percent }}</span>
              <div
                class="for_select"
                @click="nlawStop = 3"
                v-if="$route.query.is_create.toString() === '1'"
              >
                <van-checkbox
                  v-model="flags.nlaw.children[index].flag"
                  checked-color="#F01C1C"
                >
                </van-checkbox>
              </div>
            </div>
            <!-- 内容 -->
            <div
              class="items"
              v-for="(element, num) in item.data"
              :style="{
                height: flags.nlaw.children[index].children[num].flag
                  ? HelpAnimation.nlaw.children[index].children[num].hgt + 'px'
                  : '0px',
                margin: flags.nlaw.children[index].children[num].flag
                  ? ''
                  : '0px'
              }"
              :key="num"
            >
              <div class="Title">
                <span
                  @click="
                    showLawDetail(item.law_id, element.title, element.text)
                  "
                  >{{ element.title }}</span
                >
                <span
                  @click="
                    showAppealDetail(
                      '' + item.name + '' + element.title,
                      element.offset,
                      'entity'
                    )
                  "
                  >{{ element.percent }}</span
                >
                <div
                  class="for_select"
                  @click="nlawStop = 3"
                  v-if="$route.query.is_create.toString() === '1'"
                >
                  <van-checkbox
                    v-model="flags.nlaw.children[index].children[num].flag"
                    checked-color="#F01C1C"
                  >
                  </van-checkbox>
                </div>
              </div>
              <div style="display:flex;width:100%;justify-content:center;">
                <van-loading v-if="!element.text" />
              </div>
              <div class="content" v-if="element.text.length > 0">
                {{ element.text }}
              </div>
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('nlaw')"
          >
            下一页
          </div>
        </div>
        <!-- 探索法 -->
        <div
          ref="law"
          class="lawyer"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.law.flag ? HelpAnimation['law'].hgt + 'px' : '0px',
            opacity: flags.law.flag ? '1' : '0'
          }"
          v-if="data.law.length > 0"
        >
          <div class="text_title">
            辩论使用的法律法规
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox v-model="flags.law.flag" checked-color="#F01C1C">
              </van-checkbox>
            </div>
          </div>
          <div
            class="ts"
            v-for="(item, index) in data.law"
            :style="{
              height: flags.law.children[index].flag
                ? HelpAnimation.law.children[index].hgt + 'px'
                : '0px',
              margin: flags.law.children[index].flag ? '' : '0px',
              opacity: flags.law.children[index].flag ? '1' : '0',
              padding: flags.law.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <!-- 总标题 -->
            <div class="_title">
              <span>{{ item.name }}</span>
              <span>{{ item.percent }}</span>
              <div
                class="for_select"
                v-if="$route.query.is_create.toString() === '1'"
              >
                <van-checkbox
                  v-model="flags.law.children[index].flag"
                  checked-color="#F01C1C"
                >
                </van-checkbox>
              </div>
            </div>
            <!-- 内容 -->
            <div
              class="items"
              v-for="(element, num) in item.data"
              :style="{
                height: flags.law.children[index].children[num].flag
                  ? HelpAnimation.law.children[index].children[num].hgt + 'px'
                  : '0px',
                margin: flags.law.children[index].children[num].flag
                  ? ''
                  : '0px'
              }"
              :key="num"
            >
              <div class="Title">
                <span
                  @click="
                    showLawDetail(item.law_id, element.title, element.text)
                  "
                  >{{ element.title }}</span
                >
                <span
                  @click="
                    showAppealDetail(
                      '' + item.name + '' + element.title,
                      element.offset,
                      'explore'
                    )
                  "
                  >{{ element.percent }}</span
                >
                <div
                  class="for_select"
                  v-if="$route.query.is_create.toString() === '1'"
                >
                  <van-checkbox
                    v-model="flags.law.children[index].children[num].flag"
                    checked-color="#F01C1C"
                  >
                  </van-checkbox>
                </div>
              </div>
              <div style="display:flex;width:100%;justify-content:center;">
                <van-loading v-if="!element.text" />
              </div>
              <div class="content" v-if="element.text.length > 0">
                {{ element.text }}
              </div>
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('law')"
          >
            下一页
          </div>
        </div>
        <!-- 争议焦点 -->
        <div
          ref="focus"
          class="lawyer"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.focus.flag
              ? HelpAnimation['focus'].hgt + 'px'
              : '0px',
            opacity: flags.focus.flag ? '1' : '0'
          }"
          v-if="data.focus.length > 0"
        >
          <div class="text_title">
            争议焦点
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.focus.flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="focus_items"
            v-for="(item, index) in data.focus"
            :style="{
              height: flags.focus.children[index].flag
                ? HelpAnimation['focus'].children[index].hgt + 'px'
                : '0px',
              opacity: flags.focus.children[index].flag ? '1' : '0',
              overflow: 'hidden',
              margin: flags.focus.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <!-- {{item.case}} -->
            <div
              class="_title"
              v-for="(_item, num) in item.case"
              @click="BeHot('focus', index, num, item.case[0], _item.text)"
              :key="num"
            >
              {{ _item.text }}
            </div>
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.focus.children[index].flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
            <div class="tip_line">
              <div>
                <i class="iconfont icon-fenxi"></i>
                <span>{{ item.percent }}</span>
              </div>
              <div @click="morefocus(item.case[0], index)">
                查看更多
              </div>
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('focus')"
          >
            下一页
          </div>
        </div>
        <!-- 证据名称 -->
        <div
          ref="evt"
          class="lawyer"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.evt.flag ? HelpAnimation['evt'].hgt + 'px' : '0px',
            opacity: flags.evt.flag ? '1' : '0'
          }"
          v-if="data.evt.length > 0"
        >
          <div class="text_title">
            证据名称
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.evt.flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="focus_items"
            v-for="(item, index) in data.evt"
            :style="{
              height: flags.evt.children[index].flag
                ? HelpAnimation['evt'].children[index].hgt + 'px'
                : '0px',
              opacity: flags.evt.children[index].flag ? '1' : '0',
              overflow: 'hidden',
              margin: flags.evt.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <div
              class="_title"
              v-for="(_item, num) in item.case"
              @click="BeHot('evt', index, num, item.case[0], _item.text)"
              :key="num"
            >
              {{ _item.text }}
            </div>
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.evt.children[index].flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
            <div class="tip_line">
              <div>
                <i class="iconfont icon-fenxi"></i>
                <span>{{ item.percent }}</span>
              </div>
              <div @click="moreevt(item.case[0], index)">
                查看更多
              </div>
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('evt')"
          >
            下一页
          </div>
        </div>
        <!-- 本院认为 -->
        <div
          class="lawyer"
          ref="deem"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.deem.flag ? HelpAnimation['deem'].hgt + 'px' : '0px',
            opacity: flags.deem.flag ? '1' : '0'
          }"
          v-if="data.deem.length > 0"
        >
          <div class="text_title">
            本院认为
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.deem.flag"
                v-if="$route.query.is_create.toString() === '1'"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="deem_item"
            v-for="(item, index) in data.deem"
            :style="{
              height: flags.deem.children[index].flag
                ? HelpAnimation['deem'].children[index].hgt + 'px'
                : '0px',
              opacity: flags.deem.children[index].flag ? '1' : '0',
              margin: flags.deem.children[index].flag ? '' : '0px',
              padding: flags.deem.children[index].flag ? '' : '0px',
              overflow: 'hidden'
            }"
            :key="index"
          >
            <div
              class="text"
              @click="GoDetail(item.doc_id, item.text, 'deem', item.text)"
              :style="{ '-webkit-line-clamp': item.flag ? '' : '4' }"
            >
              {{ item.text }}
            </div>
            <div class="for_select">
              <van-checkbox
                v-model="flags.deem.children[index].flag"
                v-if="$route.query.is_create.toString() === '1'"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
            <div
              class="more"
              :style="{ right: item.text.length < 20 ? '0.8rem' : '' }"
              @click="item.flag = !item.flag"
            >
              {{ item.flag ? '收起' : '展开' }}
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('deem')"
          >
            下一页
          </div>
        </div>
        <!--诉请裁判额外信息-->
        <div
          class="lawyer"
          ref="lawyer"
          v-if="data.lawyer.length > 0"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.lawyer.flag
              ? HelpAnimation['lawyer'].hgt + 'px'
              : '0px',
            opacity: flags.lawyer.flag ? '1' : '0'
          }"
        >
          <div class="text_title">
            诉请裁判
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.lawyer.flag"
                v-if="$route.query.is_create.toString() === '1'"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="lawyer_item"
            v-for="(item, index) in data.lawyer"
            :style="{
              height: flags.lawyer.children[index].flag
                ? HelpAnimation['lawyer'].children[index].hgt + 'px'
                : '0px',
              opacity: flags.lawyer.children[index].flag ? '1' : '0',
              margin: flags.lawyer.children[index].flag ? '' : '0',
              padding: flags.lawyer.children[index].flag ? '' : '0'
            }"
            :key="index"
          >
            <div class="for_select">
              <van-checkbox
                v-model="flags.lawyer.children[index].flag"
                v-if="$route.query.is_create.toString() === '1'"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
            <!-- <div>{{item}}</div> -->
            <!-- <div class="text_area"
                 v-if="item.上诉请求">
              <div class="_title">原审诉讼请求</div>
            </div>
            <span v-if="item.上诉请求">
              <ul class="appeal_title">
                <li v-for="(_item,num) in item.上诉请求"
                    @click="GoDetail(item.doc_id,_item,'lawyer',_item)"
                    :key="num">{{num + 1}} . {{_item}}</li>
              </ul>
            </span> -->
            <label v-for="(Item, numb) in item.data" :key="numb">
              <div class="_title">{{ Item.title }}</div>
              <span
                v-for="(_item, num) in Item.data"
                @click="GoDetail(item.doc_id, _item, 'lawyer', _item)"
                :key="num"
              >
                <!-- <div class="request">{{num + 1}} . {{_item}}</div> -->
                <!-- 参照上面 -->
                <div class="request">{{ _item }}</div>
              </span>
            </label>
            <!-- <div class="_title"
                 v-if="item.原审裁判结果">原审裁判结果</div>
            <span v-if="item.原审裁判结果">
              <div class="deem"
                   v-for="(_item,num) in item.原审裁判结果"
                   @click="GoDetail(item.doc_id,_item,'lawyer',_item)"
                   :key="num">{{num + 1}} . {{_item}}</div>
            </span> -->
            <!-- <div class="_title"
                 v-if="item.法院判决">法院判决</div>
            <span v-if="item.法院判决">
              <div class="deem"
                   v-for="(_item,num) in item.法院判决"
                   @click="GoDetail(item.doc_id,_item,'lawyer',_item)"
                   :key="num">{{num + 1}} . {{_item}}</div>
            </span> -->
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('lawyer')"
          >
            下一页
          </div>
        </div>
        <!-- 诉讼时间 -->
        <div
          class="lawyer"
          ref="litigation"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.litigation.flag
              ? HelpAnimation['litigation'].hgt + 'px'
              : '0px',
            opacity: flags.litigation.flag ? '1' : '0'
          }"
          v-if="data.litigation.length > 0"
        >
          <div class="text_title">
            诉讼时间
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.litigation.flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="time_items"
            v-for="(item, index) in data.litigation"
            :style="{
              height: flags.litigation.children[index].flag
                ? HelpAnimation['litigation'].children[index].hgt + 'px'
                : '0px',
              margin: flags.litigation.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <div class="time_title">
              <span>{{ item.name }}</span>
              <div
                class="for_select"
                v-if="$route.query.is_create.toString() === '1'"
              >
                <van-checkbox
                  v-model="flags.litigation.children[index].flag"
                  checked-color="#F01C1C"
                ></van-checkbox>
              </div>
            </div>
            <div class="vcharts">
              <VeHistogram
                :tooltip="item.params.tooltip"
                :tooltip-visible="false"
                :grid="item.params.grid"
                :xAxis="item.params.xAxis"
                :yAxis="item.params.yAxis"
                :series="item.params.series"
                :settings="item.params"
                :colors="item.params.color"
              ></VeHistogram>
            </div>
          </div>
        </div>
        <!-- 主体判例 -->
        <div
          ref="body"
          class="lawyer"
          :style="{
            overflow: 'hidden',
            width: '100%',
            height: flags.body.flag ? HelpAnimation['body'].hgt + 'px' : '0px',
            opacity: flags.body.flag ? '1' : '0'
          }"
          v-if="data.body.length > 0"
        >
          <div class="text_title">
            主体判例
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.body.flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="body_item"
            v-for="(item, index) in data.body"
            :style="{
              height: flags.body.children[index].flag
                ? HelpAnimation['body'].children[index].hgt + 'px'
                : '0px',
              opacity: flags.body.children[index].flag ? '1' : '0',
              margin: flags.body.children[index].flag ? '' : '0px',
              padding: flags.body.children[index].flag ? '' : '0px'
            }"
            :key="index"
          >
            <div class="title">{{ item }}</div>
            <div
              class="for_select"
              v-if="$route.query.is_create.toString() === '1'"
            >
              <van-checkbox
                v-model="flags.body.children[index].flag"
                checked-color="#F01C1C"
              ></van-checkbox>
            </div>
          </div>
          <div
            class="next_page"
            v-if="$route.query.is_create.toString() === '1'"
            @click="next_page('body')"
          >
            下一页
          </div>
        </div>
        <button
          class="submit"
          v-if="$route.query.is_create.toString() === '1'"
          @click="submit"
          :disabled="is_submit"
        >
          {{ is_submit ? '正在提交报告' : '生成报告' }}
        </button>
        <!-- <button class="submit"
                style="float:right;"
                v-if="$route.query.is_create.toString()==='1'"
                @click="into_watch"
                :disabled='is_submit'>预览报告</button> -->
        <div class="load" v-if="showflag">
          <span>{{
            $route.query.is_create.toString() === '1'
              ? '加载中'
              : '正在生成报告中'
          }}</span>
          <van-loading type="spinner" />
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <div class="scrollToTop" v-if="toTop" @click="$refs.create.scrollTop = 0">
      <i class="iconfont icon-zhiding"></i>
    </div>
    <div class="remove_list" v-if="!$route.query.id" ref="remove_list">
      <fb-header slot="header" :title="'删除列表'">
        <fb-button
          slot="left"
          icon="back"
          size="small"
          @click="back"
          type="primary"
        >
        </fb-button>
      </fb-header>
      <removelist
        :view="HelpAnimation"
        v-model="flags"
        :nlaw="flags.nlaw"
        :module="data"
      ></removelist>
    </div>
    <appeal-list
      @HideAppealDetail="HideAppealDetail"
      @showDetail="changeId"
      :options="appealOpt"
      ref="appeal_detail"
    />
    <div class="detail_area" v-if="appealDetail">
      <percent-detail
        :id="detailshow.doc_id"
        :words="detailshow.words"
        :forword="detailshow.forword"
        :titleName="detailshow.name"
        @changeflag="appealDetail = false"
      />
    </div>
    <div class="detail_area" v-if="lawflag">
      <law-detail
        :id="lawDetail.law_id"
        :title="lawDetail.title"
        :catlog="lawDetail.catlog"
        :words="lawDetail.words"
        @showflag="lawflag = false"
      />
    </div>
    <div v-if="haha" class="skyDrive">
      <skyDrive
        :report="report"
        :listname="listname"
        :listid="listid"
        @CancelCollect="close"
        @getId="getId"
      ></skyDrive>
    </div>
  </div>
</template>

<script>
import taggle from './taggleBottomMixin.js'
import removelist from './remove_list'
import { caseBase, pydataBase } from '~api-config'
import VeHistogram from 'v-charts/lib/histogram.common'
import AppealList from './appeal_items'
import percentDetail from '@/pages/precedentDetail'
import lawDetail from '@/pages/Search/pages/lawDetail/index'
import { mapState, mapGetters } from 'vuex'
// import deem from './deem'
require('./queue.js')
export default {
  components: {
    skyDrive: () => import('@/pages/skyDrive/index'),
    removelist,
    VeHistogram,
    AppealList,
    percentDetail,
    lawDetail
  },
  mixins: [taggle],
  data() {
    // this.$store.state.ana.summary = '抚养费纠纷'
    // this.$store.state.ana.caseName = '离婚'
    // this.$store.state.ana.token = 'LSDtRWpah ZwfV2a7VB7eK7r1TwaFAgCpYgTVRGdvos='
    return {
      listid: '',
      listname: '',
      report: '生成报告',
      haha: false,
      toTop: false,
      focus_doc_id: [],
      evt_doc_id: [],
      lawDetail: {
        law_id: '',
        title: '',
        words: '',
        catlog: ''
      },
      appealDetail: false,
      lawflag: false,
      detailshow: {
        doc_id: '',
        words: '',
        forword: ''
      },
      remove_data: {
        appeal: []
      },
      appealOpt: {
        token: this.$store.state.ana.token,
        text: '',
        summary: this.$store.state.ana.summary,
        key: '',
        offset: '',
        offset_child: 1
      },
      appeal_flag: true,
      show: false,
      url: '',
      nlawStop: -1,
      checked: false,
      params: {},
      queue: window.queue, // 队列管理
      data: {
        // 数据层
        lawyer: [],
        deem: [],
        focus: [],
        evt: [],
        appeal: [],
        litigation: [],
        law: [],
        nlaw: [],
        case: [],
        body: []
      },
      is_submit: false,
      showflag: true,
      // ['deem', 'lawyer', 'litigation', 'evt', 'law', 'nlaw', 'focus', 'case','body']
      // ['case', 'nlaw', 'law', 'focus', 'deem', 'lawyer', 'evt', 'litigation', 'body']
      // 'case', 'nlaw', 'law', 'focus', 'deem', 'lawyer', 'evt', 'litigation', 'body'
      request: [
        'case',
        'nlaw',
        'law',
        'focus',
        'deem',
        'lawyer',
        'evt',
        'litigation',
        'body'
      ],
      flags: {
        // 逻辑控制层
        lawyer: {
          // 代理律师
          flag: true,
          children: []
        },
        litigation: {
          // 诉讼时间
          flag: true,
          children: []
        },
        case: {
          // 相似判例
          flag: true,
          children: []
        },
        deem: {
          // 法院对比
          flag: true,
          children: []
        },
        focus: {
          flag: true,
          children: []
        }, // 争议焦点
        evt: {
          // 证据名称
          flag: true,
          children: []
        },
        appeal: {
          // 诉请裁判
          flag: true,
          children: []
        },
        law: {
          // 探索法
          flag: true,
          children: []
        },
        nlaw: {
          // 实体法
          flag: true,
          children: [] // 理论还有子集
        },
        body: {
          flag: true,
          children: []
        },
        ms: true // 描述
      },
      HelpAnimation: {
        // 辅助动画高度(视图展示层)
        lawyer: {
          // 诉请裁判详情模块高度
          hgt: null,
          children: []
        },
        litigation: {
          // 诉讼时间模块高度
          hgt: null,
          children: []
        },
        body: {
          hgt: null,
          children: []
        },
        court: null, // 法院对比模块高度
        focus: {
          hgt: null,
          children: []
        }, // 争议焦点模块高度
        evt: {
          // 证据名称模块高度
          hgt: null,
          children: []
        },
        case: {
          hgt: null,
          children: []
        }, // 诉请裁判模块高度
        law: {
          // 探索法模块高度
          hgt: null,
          children: []
        },
        nlaw: {
          // 实体法模块高度
          hgt: null,
          children: [] // 理论还有子集
        },
        deem: {
          hgt: null,
          children: []
        },
        ms: null // 描述模块高度
      },
      bodyWdt: document.documentElement.offsetWidth,
      ToRemove: null,
      Move: null,
      EndMove: null,
      move_flag: true,
      past_len: 0,
      page_opt: {
        case: 1,
        nlaw: 1,
        lawyer: 1,
        deem: 1,
        law: 1,
        focus: 1,
        evt: 1,
        body: 1
      },
      isload: true
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    }),
    ...mapGetters('user', ['getUserInfo']),
    userId() {
      return this.getUserInfo.id || null
    }
  },
  methods: {
    download() {
      // 下载生成报告
      // this.data.case 相似判例
      // this.data.nlaw 法律法规
      // this.data.focus 争议焦点
      // this.data.deem 本院认为
      // this.data.lawyer 诉请裁判
      // this.data.litigation 诉讼时间
      const obj = {
        children: [
          {
            children: [
              {
                name:
                  '根据您提供的案情，通过大数据人工智能技术处理，找到相似判例，并进行专业分析，得出如下结论',
                text: '再次输入想法'
              },
              {
                name: '案情描述',
                text: this.collectParams().caseName
              }
            ]
          },
          {
            name: '相似判例',
            children: []
          },
          {
            name: '法律法规',
            children: []
          },
          {
            name: '争议焦点',
            children: []
          },
          {
            name: '本院认为',
            children: []
          },
          {
            name: '诉请裁判',
            children: []
          }
        ],
        name: '案情的分析报告'
      }
      // 相似判例
      this.data.case.forEach(item => {
        obj.children[1].children.push({
          children: [],
          docid: item.doc_id,
          name: '',
          text: item.case_name,
          type: 0
        })
      })
      // 法律法规
      this.data.nlaw.forEach(item => {
        obj.children[2].children.push({
          lawid: item.law_id,
          name: item.name,
          text: '',
          children: [],
          type: 0
        })
        item.data.forEach(itemr => {
          obj.children[2].children.forEach(itemt => {
            itemt.children.push({
              text: itemr.text,
              name: itemr.title,
              type: 4,
              lawid: item.law_id,
              children: []
            })
          })
        })
      })
      // 争议焦点
      this.data.focus.forEach(item => {
        item.case.forEach(itemr => {
          obj.children[3].children.push({
            children: [],
            docid: itemr.doc_id,
            name: '',
            text: itemr.text,
            type: 0
          })
        })
      })
      // 本院认为
      this.data.deem.forEach(item => {
        obj.children[4].children.push({
          children: [],
          docid: item.doc_id,
          name: '',
          text: item.text,
          type: 0
        })
      })
      // 诉请裁判
      this.data.lawyer.forEach(item => {
        obj.children[5].children.push({
          children: [],
          docid: item.doc_id,
          name: '上诉请求:' + item['上诉请求'].join(''),
          text: '法院判决:' + item['法院判决'].join(''),
          type: 0
        })
      })
      console.log(obj)
      this.$axios
        .post(
          `${caseBase}/document/user/${
            this.$store.state.user.userInfo.id
          }/fragment`,
          obj
        )
        .then(res => {})
    },
    getId(id) {
      this.$notify({
        background: 'rgb(25, 137, 250)',
        duration: 1000,
        message: '收藏成功,点击查看收藏'
      }).addEventListener(() => {
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
          this.$notify.clear()
        }, 100)
      })
    },
    close(flag) {
      this.haha = false
      if (flag) {
      } else {
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏'
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
        //
      }
    },
    collection() {
      const opt = this.collectParams()
      this.listname = `${
        this.$store.state.user.userInfo.nickName
      }律师的分析报告`
      this.$http
        .post(`${caseBase}/management/legal/case/report`, {
          content: JSON.stringify(opt)
        })
        .then(res => {
          this.listid = res.data.id
          this.haha = true
        })
    },
    showLawDetail(id, titleName, words) {
      if (words.trim().length === 0) {
        this.$toast('亲~,请等我加载完毕可好')
        return
      }
      this.lawDetail.law_id = id
      this.lawDetail.catlog = titleName
      this.lawDetail.title = words
      this.lawDetail.words = [words]
      this.lawflag = true
    },
    GoDetail(id, words, forword, titleName) {
      this.detailshow.doc_id = id
      this.detailshow.words = [words]
      this.detailshow.forword = forword
      this.detailshow.titleName = titleName
      this.appealDetail = true
    },
    BeHot(name, index, num, item, titleName) {
      switch (name) {
        case 'focus':
          if (num === 0 && !this.focus_doc_id[index][num]) {
            this.morefocus(item, index, true)
          } else {
            this.GoDetail(
              this.focus_doc_id[index][num],
              item,
              'focus',
              titleName
            )
          }
          break
        case 'evt':
          if (num === 0 && !this.evt_doc_id[index][num]) {
            this.moreevt(item, index, true)
          } else {
            this.GoDetail(this.focus_doc_id[index][num], item, 'evt', titleName)
          }
          break
      }
    },
    changeId(id) {
      this.$set(this.detailshow, 'doc_id', id)
      this.appealDetail = true
    },
    showAppealDetail(text, offset, key, offset_child = 1) {
      for (const item in { text, offset, key, offset_child }) {
        this.appealOpt[item] = { text, offset, key, offset_child }[item]
      }
      this.$refs.appeal_detail.$el.style.top = '0px'
      this.$refs.appeal_detail.$el.style.left = '0px'
    },
    HideAppealDetail() {
      this.$refs.appeal_detail.$el.style.top = '-100%'
      this.$refs.appeal_detail.$el.style.left = '-100%'
    },
    morefocus(item, index, flag) {
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        type: 'loading'
      })
      this.get_more(item, 'focus', this.data.focus[index].offset, res => {
        this.$toast.clear()
        res.data.result.datas.forEach((_item, num) => {
          if (
            this.focus_doc_id[index].length === 0 &&
            this.data.focus[index].offset === 1
          ) {
            this.focus_doc_id[index].push(_item.doc_id)
            if (!flag) {
              this.data.focus[index].offset++
              this.morefocus(item, index)
            }
            return false
          }
          this.focus_doc_id[index].push(_item.doc_id)
          this.data.focus[index].case.push(_item)
        })
        if (flag) {
          this.GoDetail(this.focus_doc_id[index][0], item, 'focus', item)
          this.appealDetail = true
        }
        this.data.focus[index].offset++
        this.resetHgt()
        this.get_hgt()
      })
    },
    moreevt(item, index, flag) {
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        type: 'loading'
      })
      this.get_more(item, 'evt', this.data.evt[index].offset, res => {
        this.$toast.clear()
        res.data.result.datas.forEach(_item => {
          if (
            this.evt_doc_id[index].length === 0 &&
            this.data.evt[index].offset === 1
          ) {
            this.evt_doc_id[index].push(_item.doc_id)
            if (!flag) {
              this.data.evt[index].offset++
              this.moreevt(item, index)
            }
            return false
          }
          this.evt_doc_id[index].push(_item.doc_id)
          this.data.evt[index].case.push(_item)
        })
        if (flag) {
          this.GoDetail(this.evt_doc_id[index][0], item, 'evt', item)
        }
        this.data.evt[index].offset++
        this.resetHgt()
        this.get_hgt()
      })
    },
    get_more(name, key, offset, callback) {
      this.$axios
        .post(
          `${pydataBase}/api/web/cal_similitude/more?sentence=${
            this.$store.state.ana.caseName
          }&keyword=&summary=${
            this.$store.state.ana.summary
          }&key=${key}&offset=${offset}&text=${name}&condition=`
        )
        .then(callback)
    },
    IsPc() {
      const userAgentInfo = window.navigator.userAgent
      const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      ]
      let flag = true
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    resetHgt() {
      // 重新计算高度
      for (const item in this.$refs) {
        // 重新计算高度必须先清空高度达到自适应
        if (item === 'ms') {
        } else if (item === 'case') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags.case.flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (
              index === 0 ||
              index === this.$refs[item].children.length - 1 ||
              !this.flags[item].children[index - 1].flag
            ) {
              return
            }
            _item.style.height = ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
        } else if (item === 'nlaw') {
          this.HelpAnimation[item].hgt = null
          this.$refs[item].style.height =
            this.$refs[item].style.height === '0px' && !this.flags[item].flag
              ? '0px'
              : ''
          this.HelpAnimation[item].children.forEach((_i, index) => {
            this.$set(this.HelpAnimation[item].children[index], 'hgt', null)
            if (!this.$refs[item].children[index + 1]) {
              return
            }
            this.$refs[item].children[index + 1].style.height =
              this.$refs[item].children[index + 1].style.height === '0px' &&
              !this.flags[item].children[index].flag
                ? '0px'
                : ''
            /*
            this.HelpAnimation[item].children[index].children.forEach(
              (_it, num) => {
                this.$refs[item].children[index + 1].children[
                  num + 1
                ].style.height =
                  this.$refs[item].children[index + 1].children[num + 1].style
                    .height === '0px' &&
                  !this.flags[item].children[index].children[num].flag
                    ? '0px'
                    : ''
                this.$set(
                  this.HelpAnimation[item].children[index].children[num],
                  'hgt',
                  null
                )
              }
            )
            */
          })
        } else if (item === 'law') {
          this.HelpAnimation[item].hgt = null
          this.$refs[item].style.height =
            this.$refs[item].style.height === '0px' && !this.flags[item].flag
              ? '0px'
              : ''
          this.HelpAnimation[item].children.forEach((_i, index) => {
            this.$set(this.HelpAnimation[item].children[index], 'hgt', null)
            if (!this.$refs[item].children[index + 1]) {
              return
            }
            this.$refs[item].children[index + 1].style.height =
              this.$refs[item].children[index + 1].style.height === '0px' &&
              !this.flags[item].children[index].flag
                ? '0px'
                : ''
            /*
            this.HelpAnimation[item].children[index].children.forEach(
              (_it, num) => {
                this.$refs[item].children[index + 1].children[
                  num + 1
                ].style.height =
                  this.$refs[item].children[index + 1].children[num + 1].style
                    .height === '0px' &&
                  !this.flags[item].children[index].children[num].flag
                    ? '0px'
                    : ''
                this.$set(
                  this.HelpAnimation[item].children[index].children[num],
                  'hgt',
                  null
                )
              }
            )
            */
          })
        } else if (item === 'lawyer') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags.lawyer.flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0 || index === this.$refs[item].children.length - 1) {
              return false
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
        } else if (item === 'deem') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags[item].flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0 || index === this.$refs[item].children.length - 1) {
              return
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
        } else if (item === 'focus') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags.focus.flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0 || index === this.$refs[item].children.length - 1) {
              return
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
          })
        } else if (item === 'evt') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 ? '0px' : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0 || index === this.$refs[item].children.length - 1) {
              return
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
        } else if (item === 'body') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags[item].flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0 || index === this.$refs[item].children.length - 1) {
              return
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
          this.showflag = false
        } else if (item === 'litigation') {
          this.$refs[item].style.height =
            this.$refs[item].offsetHeight === 0 && !this.flags[item].flag
              ? '0px'
              : ''
          this.$set(this.HelpAnimation[item], 'hgt', null)
          Array.from(this.$refs[item].children).forEach((_item, index) => {
            if (index === 0) {
              return
            }
            _item.style.height =
              _item.offsetHeight === 0 &&
              !this.flags[item].children[index - 1].flag
                ? '0px'
                : ''
            this.$set(this.HelpAnimation[item].children[index - 1], 'hgt', null)
          })
        }
      }
    },
    next_page(name) {
      this.page_opt[name]++
      this.get_data(name, 0, false, this.page_opt[name])
    },
    get_hgt() {
      // 获取动画的收缩高度
      setTimeout(() => {
        for (const item in this.$refs) {
          if (item === 'ms') {
            continue
          } else if (item === 'case') {
            this.HelpAnimation.case.hgt = this.$refs[item].offsetHeight
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              // 遍历子集
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return
              } // 标题无需遍历
              this.$set(
                this.HelpAnimation.case.children[index - 1],
                'hgt',
                _item.offsetHeight
              )
            })
            continue
          } else if (item === 'nlaw') {
            // 获取裁判依据模块的高度
            this.HelpAnimation[item].hgt =
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            Array.from(this.$refs[item].children).forEach((itemr, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return
              }
              this.$set(
                this.HelpAnimation.nlaw.children[index - 1],
                'hgt',
                itemr.offsetHeight
              )
              Array.from(this.$refs[item].children[index].children).forEach(
                (element, num) => {
                  /*
                  if (
                    num === 0 ||
                    num === this.$refs[item].children.length - 1
                  ) {
                    return
                  }
                  this.$set(
                    this.HelpAnimation.nlaw.children[index - 1][
                      children[num - 1]
                    ],
                    'hgt',
                    element.offsetHeight
                  )
                  */
                }
              )
            })
            continue
          } else if (item === 'law') {
            this.HelpAnimation[item].hgt =
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            Array.from(this.$refs[item].children).forEach((itemr, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return
              }
              this.$set(
                this.HelpAnimation.law.children[index - 1],
                'hgt',
                itemr.offsetHeight
              )
              Array.from(this.$refs[item].children[index].children).forEach(
                (element, num) => {
                  /*
                  if (num === 0) {
                    return
                  }
                  this.$set(
                    this.HelpAnimation.law.children[index - 1].children[
                      num - 1
                    ],
                    'hgt',
                    element.offsetHeight
                  )
                  */
                }
              )
            })
            continue
          } else if (item === 'lawyer') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            this.HelpAnimation[item].children.forEach((_item, index) => {
              this.$set(this.HelpAnimation[item].children, index, {
                hgt: this.$refs[item].children[index + 1].offsetHeight
              })
            })
            continue
          } else if (item === 'deem') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return
              }
              this.$set(
                this.HelpAnimation[item].children[index - 1],
                'hgt',
                _item.offsetHeight
              )
            })
            continue
          } else if (item === 'focus') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return false
              }
              this.HelpAnimation[item].children[index - 1].hgt =
                _item.offsetHeight
            })
            continue
          } else if (item === 'evt') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return false
              }
              this.HelpAnimation[item].children[index - 1].hgt =
                _item.offsetHeight
            })
            continue
          } else if (item === 'body') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              if (
                index === 0 ||
                index === this.$refs[item].children.length - 1
              ) {
                return false
              }
              this.HelpAnimation[item].children[index - 1].hgt =
                _item.offsetHeight
            })
            this.showflag = true
            continue
          } else if (item === 'litigation') {
            this.$set(
              this.HelpAnimation[item],
              'hgt',
              this.$refs[item].offsetHeight === 0
                ? null
                : this.$refs[item].offsetHeight
            )
            Array.from(this.$refs[item].children).forEach((_item, index) => {
              if (index === 0) {
                return false
              }
              this.HelpAnimation[item].children[index - 1].hgt =
                _item.offsetHeight
            })
            continue
          }
          this.HelpAnimation[item] =
            this.$refs[item].offsetHeight === 0
              ? null
              : this.$refs[item].offsetHeight
          this.$toast.clear()
        }
      }, 1000)
    },
    get_data(name, times, flag, offset = 1) {
      this.$toast({
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      // setTimeout(() => {
      this.resetHgt()
      this.isload = true
      const param = {
        summary: this.$store.state.ana.summary,
        sentence: this.$store.state.ana.caseName,
        key: name === 'lawyer' ? 'appeal' : name === 'nlaw' ? 'law' : name,
        offset
      }
      this.$http({
        method: 'POST',
        url: `${pydataBase}/api/ai/semantic/category`,
        data: param
      })
        .then(res => {
          if (res.data.result) {
            this.$toast.clear()
            this.setData(res, name, flag)
            this.get_hgt()
            this.isload = false
          }
        })
        .catch(err => {
          if (err.response) {
            const code = err.response.status && parseInt(err.response.status)
            if (code === 500) {
              this.$notify({
                message: '9无数据更新',
                background: '#999'
              })
            }
          } else {
            this.$notify({
              message: '9无数据更新',
              background: '#999'
            })
          }
        })
    },
    setData(res, name, flag) {
      // 数据赋值方法
      if (name === 'court' || flag) {
        this.showflag = false
      } else if (name === 'case') {
        // 相似判例单独请求
        this.data[name] = this.data[name].concat(
          res.data.result.datas.slice(0, 10)
        )
        this.data[name].forEach((item, index) => {
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index]
            : { flag: true }
          this.$set(this.flags[name].children, index, flag)
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
        setTimeout(() => {
          // 防止无法加载
          this.$refs.create.scrollTop += 300
        }, 1000)
        return false
      } else if (name === 'nlaw') {
        // 法律法规单独请求
        if (res.data.result.datas.length === 0) {
          this.$toast('已经没有更多了')
          return
        }
        this.data.nlaw = this.data.nlaw.concat(
          res.data.result.datas
            .filter(item => {
              return item.name === '实体法'
            })[0]
            .values.slice(0, 5)
        )
        this.data.nlaw.map((_item, num) => {
          _item.data.map((item, index) => {
            if (!item.offset) {
              this.$set(
                this.data.nlaw[num].data[index],
                'offset',
                this.page_opt[name]
              )
            }
            if (item.text.trim().length > 0) {
              return item
            }
            this.queue.Add(
              this.$axios,
              {
                url: `${pydataBase}/api/law/content?law=${'《' +
                  _item.name +
                  '》' +
                  item.title}`,
                method: 'GET'
              },
              res => {
                this.resetHgt()
                this.$set(
                  this.data.nlaw[num].data[index],
                  'text',
                  res.data.result
                )
                this.$set(
                  this.data.nlaw[num].data[index],
                  'law_id',
                  res.data.law_id
                )
                if (num === this.data.nlaw.length - 1) {
                  this.get_hgt()
                }
              }
            )
          })
          return _item
        })
        this.data.nlaw.forEach((item, index) => {
          this.flags.nlaw.children.push({
            flag: true,
            children: []
          })
          this.HelpAnimation.nlaw.children.push({
            hgt: null,
            children: []
          })
          this.HelpAnimation.nlaw.children[index].children = []
          item.data.forEach(() => {
            this.flags.nlaw.children[index].children.push({
              flag: true
            })
            this.HelpAnimation.nlaw.children[index].children.push({
              hgt: null
            })
          })
        })
      } else if (name === 'law') {
        if (res.data.result.datas.length === 0) {
          this.$toast('已经没有更多了')
          return
        }
        // 以下是探索法区域
        this.data[name] = this.data[name].concat(
          res.data.result.datas
            .filter(item => {
              return item.name === '探索法'
            })[0]
            .values.slice(0, 5)
        )
        this.data[name].map((_item, num) => {
          _item.data.map((item, index) => {
            if (!item.offset) {
              this.$set(
                this.data.law[num].data[index],
                'offset',
                this.page_opt[name]
              )
            }
            if (item.text.trim().length > 0) {
              return item
            }
            this.queue.Add(
              this.$axios,
              {
                url: `${pydataBase}/api/law/content?law=${'《' +
                  _item.name +
                  '》' +
                  item.title}`,
                method: 'GET'
              },
              res => {
                this.resetHgt()
                this.$set(
                  this.data[name][num].data[index],
                  'text',
                  res.data.result
                )
                this.$set(
                  this.data[name][num].data[index],
                  'law_id',
                  res.data.law_id
                )
                if (num === this.data[name].length - 1) {
                  this.get_hgt()
                }
              }
            )
          })
          this.data[name].forEach((item, index) => {
            this.flags[name].children.push({
              flag: true,
              children: []
            })
            this.HelpAnimation[name].children.push({
              hgt: null,
              children: []
            })
            this.HelpAnimation[name].children[index].children = []
            item.data.forEach(() => {
              this.flags[name].children[index].children.push({
                flag: true
              })
              this.HelpAnimation[name].children[index].children.push({
                hgt: null
              })
            })
          })
          return _item
        })
        return false
      } else if (name === 'lawyer') {
        this.data[name] = this.data[name].concat(
          res.data.result.datas.slice(0, 10)
        )
        this.data[name].forEach((item, index) => {
          item.data.forEach(_item => {
            this.$set(this.data[name][index], _item.title, _item.data)
          })
        })
        this.HelpAnimation[name].children = new Array(this.data[name].length)
        this.data[name].forEach((item, index) => {
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index].flag
            : true
          this.$set(this.flags[name].children, index, { flag: flag })
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
      } else if (name === 'deem') {
        this.data[name] = this.data[name].concat(
          res.data.result.datas.slice(0, 10)
        )
        this.data[name].forEach((item, index) => {
          this.$set(this.data[name][index], 'flag', false)
        })
        this.HelpAnimation[name].children = new Array(this.data[name].length)
        this.data[name].forEach((item, index) => {
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index].flag
            : true
          this.$set(this.flags[name].children, index, { flag: flag })
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
      } else if (name === 'focus') {
        this.data[name] = this.data[name].concat(
          res.data.result.datas.slice(0, 10)
        )
        this.HelpAnimation[name].children = new Array(
          res.data.result.datas.length
        )
        this.data[name].forEach((item, index) => {
          if (!this.data[name][index].offset) {
            this.data[name][index].offset = 1
            this.focus_doc_id.push([])
          }
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index].flag
            : true
          this.$set(this.flags[name].children, index, { flag: flag })
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
      } else if (name === 'evt') {
        this.data[name] = this.data[name].concat(
          res.data.result.datas.slice(0, 10)
        )
        this.HelpAnimation[name].children = new Array(
          res.data.result.datas.length
        )
        this.data[name].forEach((item, index) => {
          if (!this.data[name][index].offset) {
            this.data[name][index].offset = 1
            this.evt_doc_id.push([])
          }
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index].flag
            : true
          this.$set(this.flags[name].children, index, { flag: flag })
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
      } else if (name === 'body') {
        this.data[name] = this.data[name].concat(res.data.result.slice(0, 10))
        this.data[name].forEach((item, index) => {
          const flag = this.flags[name].children[index]
            ? this.flags[name].children[index].flag
            : true
          this.$set(this.flags[name].children, index, { flag: flag })
          this.$set(this.HelpAnimation[name].children, index, { hgt: null })
        })
      } else if (name === 'litigation') {
        res.data.result.datas.forEach((item, index) => {
          for (const name2 in item) {
            const paramscharts = {}
            if (name2 === 'time') {
              // 时间
              for (const name in item.time.option.option) {
                paramscharts[name] = item.time.option.option[name]
              }
            } else if (name2 === 'year') {
              // 年度
              for (const name in item.year.option.option) {
                paramscharts[name] = item.time.option.option[name]
              }
            }
            // for (let _item in item) {
            //   // eslint-disable-next-line camelcase
            //   let params_charts = {
            //     color: ['#4B9EFB'],
            //     grid: {
            //       left: '3%',
            //       right: '0%',
            //       containLabel: true,
            //       height: 230,
            //       width: '90%'
            //     },
            //     xAxis: [
            //       {
            //         type: 'category',
            //         data: [],
            //         axisTick: {
            //           alignWithLabel: true
            //         }
            //       }
            //     ],
            //     yAxis: [
            //       {
            //         type: 'value'
            //       }
            //     ],
            //     series: [
            //       {
            //         name: '直接访问',
            //         type: 'bar',
            //         data: [],
            //         barWidth: 20,
            //         itemStyle: {
            //           barBorderRadius: [70, 70, 70, 70, 70]
            //         },
            //         label: {
            //           normal: {
            //             show: true,
            //             position: 'top',
            //             textStyle: {
            //               color: '#4B9EFB',
            //               fontSize: 14,
            //               backgroundColor: 'white'
            //             },
            //             formatter (val) {
            //               // return ((val.value / sum) * 100).toFixed(2) + '%'
            //             }
            //           }
            //         }
            //       }
            //     ]
            //   }
            //   params_charts.xAxis[0].data = item[_item].title
            //   params_charts.series[0].data = item[_item].data
            this.data[name].push({
              name: name2 === 'time' ? '时间' : '年度',
              params: paramscharts
            })
            this.data[name].forEach((item, index) => {
              const flag = this.flags[name].children[index]
                ? this.flags[name].children[index].flag
                : true
              this.$set(this.flags[name].children, index, {
                flag: flag
              })
              this.$set(this.HelpAnimation[name].children, index, {
                hgt: null
              })
            })
          }
        })
      }
    },
    collectParams() {
      const opt = {
        think:
          this.$refs.think.innerText === '在此输入想法'
            ? ''
            : this.$refs.think.innerText,
        caseName: this.$store.state.ana.caseName,
        name: this.$store.state.user.userInfo.nickName,
        date:
          new Date().getFullYear() +
          '.' +
          (new Date().getMonth() + 1) +
          '.' +
          new Date().getDate(),
        data: {},
        flags: this.flags
      }
      opt.data = this.data
      // for (let item in this.flags) {
      //   if (!this.flags[item].flag) {
      //     opt.data[item] = []
      //   } else {
      //     if (item === 'nlaw' || item === 'law') {
      //       for (let i = 0; i < this.flags[item].children.length; i++) {
      //         if (!this.flags[item].children[i].flag) {
      //           opt.data[item].splice(i, 1)
      //           continue
      //         }
      //         for (let j = 0; j < this.flags[item].children[i].children.length; j++) {
      //           if (!this.flags[item].children[i].children[j].flag) {
      //             opt.data[item][i].data.splice(j, 1)
      //           }
      //         }
      //       }
      //     } else {
      //       for (let i = 0; i < this.flags[item].children.length; i++) {
      //         if (!this.flags[item].children[i].flag) {
      //           opt.data[item].splice(i, 1)
      //         }
      //       }
      //     }
      //   }
      // }

      return opt
    },
    submit() {
      const opt = this.collectParams()
      this.$http
        .post(`${caseBase}/management/legal/case/report`, {
          content: JSON.stringify(opt)
        })
        .then(res => {
          // this.$router.push({
          //   path: '/ana/create',
          //   query: {
          //     is_create: 0,
          //     id: res.data.id
          //   }
          // })
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.shareWebPg2WxFriend(
              `${opt.name}律师的分析报告 ${opt.date}`,
              '案情分析报告',
              `${window.document.location.origin}/ana/create?id=${
                res.data.id
              }&is_create=0`
            )
          } else {
            window.webkit.messageHandlers.shareCaseFile.postMessage(
              JSON.stringify({
                filename: `${opt.name}律师的分析报告 ${opt.date}`,
                url: `${window.document.location.origin}/ana/create?id=${
                  res.data.id
                }&is_create=0`
              })
            )
          }
        })
    },
    into_watch() {
      // 进入预览
      this.$router.push({
        name: 'createAna',
        query: {
          is_create: '0',
          is_watch: '1'
        },
        params: this.collectParams()
      })
    },
    back() {
      // 删除列表回退
      this.$refs.edit.style.right = 0 + 'px'
      this.$refs.remove_list.style.right = this.bodyWdt * -1 + 'px'
      this.ChangeWay(false)
    },
    ChangeWay(flag) {
      // 切换滑动函数
      let StartX = 0
      if (this.IsPc()) {
        document.body.removeEventListener('mousedown', this.ToRemove)
        document.body.removeEventListener('mousemove', this.Move)
        document.body.removeEventListener('mouseup', this.EndMove)
      } else {
        document.body.removeEventListener('touchstart', this.ToRemove)
        document.body.removeEventListener('touchmove', this.Move)
        document.body.removeEventListener('touchend', this.EndMove)
      }
      if (flag) {
        this.ToRemove = e => {
          if (e.touches.length > 1 || e.touches[0].pageX > this.bodyWdt * 0.4) {
            return false
          }
          StartX = e.touches[0].pageX
          this.move_flag = true
          this.$refs.edit.style.transition = 'all 0s'
          this.$refs.remove_list.style.transition = 'all 0s'
        }
        this.Move = e => {
          if (this.move_flag) {
            this.past_len = e.touches[0].pageX - StartX
            this.$refs.edit.style.right = this.bodyWdt - this.past_len + 'px'
            this.$refs.remove_list.style.right = this.past_len * -1 + 'px'
            // this.$refs.remove_list.style.right =  + 'px'
          }
        }
        this.EndMove = e => {
          if (this.move_flag) {
            this.$refs.edit.style.transition = ''
            this.$refs.remove_list.style.transition = ''
            if (parseInt(this.$refs.edit.style.right) < this.bodyWdt) {
              this.$refs.edit.style.right = 0 + 'px'
              this.$refs.remove_list.style.right = this.bodyWdt * -1 + 'px'
              this.ChangeWay(false)
            } else {
              this.$refs.edit.style.right = this.bodyWdt + 'px'
              this.$refs.remove_list.style.right = 0 + 'px'
            }
            this.move_flag = false
          }
        }
        if (this.IsPc()) {
          document.body.addEventListener('mousedown', this.ToRemove, false)
          document.body.addEventListener('mousemove', this.Move, false)
          document.body.addEventListener('mouseup', this.EndMove, false)
        } else {
          document.body.addEventListener('touchstart', this.ToRemove, false)
          document.body.addEventListener('touchmove', this.Move, false)
          document.body.addEventListener('touchend', this.EndMove, false)
        }
      } else {
        this.ToRemove = e => {
          try {
            if (
              e.touches.length > 1 ||
              e.touches[0].pageX < this.bodyWdt * 0.85
            ) {
              return false
            }
            StartX = e.touches[0].pageX
            this.move_flag = true
            this.$refs.edit.style.transition = 'all 0s'
            this.$refs.remove_list.style.transition = 'all 0s'
          } catch {}
        }
        this.Move = e => {
          try {
            if (this.move_flag) {
              this.past_len = e.touches[0].pageX - StartX
              this.$refs.edit.style.right =
                (this.past_len * -1 < 0 ? 0 : this.past_len * -1) + 'px'
              this.$refs.remove_list.style.right =
                ((this.bodyWdt + this.past_len) * -1 > 0
                  ? 0
                  : (this.bodyWdt + this.past_len) * -1) + 'px'
            }
          } catch {}
        }
        this.EndMove = e => {
          try {
            if (this.move_flag) {
              this.$refs.edit.style.transition = ''
              this.$refs.remove_list.style.transition = ''
              if (
                parseInt(this.$refs.remove_list.style.right) * -1 <
                this.bodyWdt * 0.8
              ) {
                this.$refs.edit.style.right = this.bodyWdt + 'px'
                this.$refs.remove_list.style.right = '0px'
                this.ChangeWay(true)
              } else {
                this.$refs.edit.style.right = '0px'
                this.$refs.remove_list.style.right = this.bodyWdt * -1 + 'px'
              }
              this.move_flag = false
            }
          } catch {}
        }
        document.body.addEventListener('touchstart', this.ToRemove, false)
        document.body.addEventListener('touchmove', this.Move, false)
        document.body.addEventListener('touchend', this.EndMove, false)
      }
    }
  },
  created() {
    // this.$store.state.ana.summary = '民间借贷纠纷'
    // this.$store.state.ana.caseName = '2015年6月7日，被告曲阜市中联理财投资咨询有限公司。因经营需要向原告借款2000万元。当时双方签订了借款合同。约定借款数额2000万元，借款期限三个月，借款利息月息二分。按照协议约定，原告支付了2000万元给被告曲阜市中联理财投资咨询有限公司，到期后被告曲阜市中联理财投资咨询有限公司未予偿还。经查，被告曲阜市中联理财投资咨询有限公司的股东为喜德润。其同时以其儿子的名义开办了曲阜市中联投资有限公司和曲阜市中联建筑有限公司。三个公司财产混同。交叉持股。共用办公地址。共用办公电话。资金相互混用，财产混同。办公地址相同。业务相同。公司都有喜德瑞一个人掌控。请求三被告连带偿还借款及利息，'
    if (this.$route.query.is_create.toString() === '1') {
      // 暂时不使用if块
    } else {
      this.$http
        .get(
          `http://api.farbun.com/management/legal/case/report/${
            this.$route.query.id
          }`
        )
        .then(res => {
          this.params = JSON.parse(res.data.content)
          this.flags = this.params.flags
          this.$store.state.ana.summary = this.params.dir
          this.$store.state.ana.caseNmae = this.params.caseName
          for (const item in this.flags) {
            if (item === 'nlaw' || item === 'law') {
              this.$set(this.HelpAnimation, item, { hgt: null })
              this.HelpAnimation[item].children = []
              this.flags[item].children.forEach((_item, index) => {
                this.$set(this.HelpAnimation[item].children, index, {
                  hgt: null,
                  children: []
                })
                _item.children.forEach((_item, num) => {
                  this.$set(
                    this.HelpAnimation[item].children[index].children,
                    num,
                    { hgt: null }
                  )
                })
              })
            } else {
              if (typeof this.flags[item] === 'boolean') {
                continue
              }
              this.$set(this.HelpAnimation, item, { hgt: null })
              this.HelpAnimation[item].children = []
              this.flags[item].children.forEach((_item, index) => {
                this.$set(this.HelpAnimation[item].children, index, {
                  hgt: null
                })
              })
            }
          }
          this.params.data.nlaw.map((_item, num) => {
            _item.data.map((item, index) => {
              if (item.text.trim().length > 0) {
                return item
              }
              this.queue.Add(
                this.$axios,
                {
                  url: `${pydataBase}/api/law/content?law=${_item.name +
                    item.title}`,
                  method: 'GET'
                },
                res => {
                  this.$set(
                    this.data.nlaw[num].data[index],
                    'text',
                    res.data.result
                  )
                }
              )
            })
            return _item
          })
          this.params.data.law.map((_item, num) => {
            _item.data.map((item, index) => {
              this.queue.Add(
                this.$axios,
                {
                  url: `${pydataBase}/api/law/content?law=${_item.name +
                    item.title}`,
                  method: 'GET'
                },
                res => {
                  this.$set(
                    this.data.law[num].data[index],
                    'text',
                    res.data.result
                  )
                  if (num === this.data.law.length - 1) {
                    this.get_hgt()
                  }
                }
              )
            })
            return _item
          })

          this.data = this.params.data
          const params = this.data.litigation
          setTimeout(() => {
            this.data.litigation = []
            setTimeout(() => {
              this.$set(this.data, 'litigation', params)
            }, 1000)
          }, 5000)
          this.showflag = false
        })
    }
    this.hiddenBottom()
  },
  mounted() {
    // data.case 相似叛逆
    if (this.$route.query.is_create.toString() === '1') {
      this.$refs.think.addEventListener(
        'click',
        function() {
          if (this.innerText === '在此输入想法') {
            this.innerText = ''
          }
        },
        false
      )
      this.$refs.think.addEventListener(
        'blur',
        function() {
          if (this.innerText.trim().length === 0) {
            this.innerText = '在此输入想法'
          }
        },
        false
      )
      this.$refs.remove_list.style.right = this.bodyWdt * -1 + 'px'
      this.ChangeWay(false)
      // 初次加载
      this.request = this.request.reverse()
      this.get_data(this.request.pop(), 0, false)
      this.layzyLoad = () => {
        // 接口懒加载
        const element = document.querySelector('.create')
        if (element.scrollTop + element.offsetHeight === element.scrollHeight) {
          // 如果下拉到最后执行请求
          if (this.request.length === 0) {
            document
              .querySelector('.create')
              .removeEventListener('scroll', this.layzyLoad)
            return false
          }
          const name = this.request.pop()
          this.get_data(name, 0, false)
        }
      }
      setTimeout(() => {
        // 获取案情描述恒定高度
        this.HelpAnimation.ms = document.getElementById('ms').offsetHeight
        this.$refs.create.onscroll = () => {
          if (this.$refs.create.scrollTop > window.screen.height) {
            this.toTop = true
          } else {
            this.toTop = false
          }
        }
      })
      document
        .querySelector('.create')
        .addEventListener('scroll', this.layzyLoad)
    }
  },
  destroyed() {
    this.queue.destroy()
    if (this.IsPc()) {
      document.body.removeEventListener('mousedown', this.ToRemove)
      document.body.removeEventListener('mousemove', this.Move)
      document.body.removeEventListener('mouseup', this.EndMove)
    } else {
      document.body.removeEventListener('touchstart', this.ToRemove)
      document.body.removeEventListener('touchmove', this.Move)
      document.body.removeEventListener('touchend', this.EndMove)
    }
  },
  watch: {
    $route() {
      if (Object.keys(this.$route.params).length > 0) {
        if (this.$route.query.is_watch === '1') {
          this.params = this.$route.params
          this.$store.state.ana.summary = this.params.dir
          this.$store.state.ana.caseNmae = this.params.caseName
          this.data = this.params.data
          this.showflag = false
        }
      }
    },
    flags: {
      deep: true,
      handler() {
        setTimeout(() => {
          // this.$toast.loading({
          //   mask: true,
          //   message: '保存操作中...',
          //   type: 'loading'
          // })
          if (this.isload) {
            return false
          }
          this.resetHgt()
          this.get_hgt()
        }, 800)
      }
    },
    'data.deem': {
      deep: true,
      handler() {
        // this.$toast.loading({
        //   mask: true,
        //   message: '保存操作中...',
        //   type: 'loading'
        // })
        setTimeout(() => {
          this.resetHgt()
          this.get_hgt()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.detail_area
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color white
  z-index 10001
*
  transition all 0.7s
.appeal_detail
  width 100%
  display inline-block
  overflow hidden
.next_page
  width 100%
  text-align center
  color #666666
  font-weight normal
  font-size 15px
.ctn
  width 100%
  height 100%
  position relative
  overflow hidden
  .edit
    width 100%
    height 100%
    position absolute
    overflow auto
    transition all 0.7s
    right 0px
    top 0px
    .create
      width 100%
      height 100%
      overflow auto
      box-sizing border-box
      padding 0px 10px 0px 10px
      position relative
    .title
      font-size 36px
      color #F01C1C
      font-weight 600
      text-align center
      margin 30px 0px 30px 0px
    .name_area
      color #333333
      font-size 15px
      text-align right
      margin-bottom 20px
      span:nth-child(1)
        margin-right 20px
      span:nth-child(2)
        margin-right 5px
    .think
      div:nth-child(1)
        width 100%
        color #333333
        font-size 16px
        font-weight 600
      .xiangfa
        margin-top 10px
        width 100%
        resize none
        font-size 16px
        font-weight 600
        white-space wrap
        word-break break-all
        min-height 20px
        border none
        outline none
    .text_title
      color #FC5744
      font-size 25px
      font-weight bold
      text-align left
      margin 38px 0px 38px 0px
      position relative
      .opc_text
        position absolute
        top 10px
        left 0px
        text-align left
        opacity 0.1
        color #F01C1C
        width 100%
      .for_select
        position absolute
        display inline-block
        right 5px
        top 3px
        z-index 10
    .text_title_content
      font-size 16px
      line-height 22px
      color #333333
      overflow hidden
      font-weight 600
    .all
      margin-top 38px
      overflow hidden
      span
        font-size 16px
        color #F01C1C
        align-self center
        vertical-align middle
        float right
        height 100%
        line-height 20px
        font-weight bold
      div
        align-self center
        float right
        vertical-align middle
        margin-left 3px
    .space
      clear both
      width 100%
      display none
      color #333333
      font-size 16px
      font-weight bold
      margin 10px 0px 50px 0px
    .appeal
      margin-top 50px
      width 100%
      overflow hidden
      .appeal_title
        width 100%
        font-size 25px
        padding 0px
        text-align left
        font-weight bold
        color #fc5744
        position relative
        margin-bottom 20px
        .for_select
          width 30px
          height 30px
          position absolute
          right 0px
          top -5px
      .des_item
        width 100%
        overflow hidden
        margin-bottom 20px
        position relative
        ._title
          font-size 16px
          color #000000
          font-weight 600
          max-width 92%
        .for_select_child
          position absolute
          top 0px
          right 0px
        .bottom_text
          width 100%
          margin-top 10px
          color #CCCCCC
          font-size 13px
    .load
      width 200px
      display flex
      justify-content center
      position absolute
      top 0px
      left calc(50% - 100px)
      top 6px
      span
        align-self center
        margin-right 10px
        color #333333
        font-size 16px
  .remove_list
    width 100%
    height 100%
    position absolute
    padding 0px 10px 0px 10px
    top 0px
    transition all 0.7s
  .submit
    display block
    width 70%
    height 35px
    font-size 16px
    color white
    margin 20px auto
    text-align center
    background-color #FC6F53
    line-height 35px
    border-radius 5px
  .load
    width 200px
    display flex
    justify-content center
    position absolute
    top 0px
    left calc(50% - 100px)
    top 6px
    span
      align-self center
      margin-right 10px
      color #333333
      font-size 16px
  .ts
    width 100%
    border 1px solid #cccccc
    overflow hidden
    border-radius 5px
    padding 10px
    box-sizing border-box
    font-size 16px
    font-weight 600
    box-shadow 0px 0px 10px #cccccc
    margin-bottom 20px
    ._title
      width 100%
      position relative
      display flex
      justify-content space-between
      span:nth-child(1)
        font-weight 600
      span:nth-child(2)
        display inline-block
        margin-right 40px
        font-size 14px
        font-weight normal
        align-self center
      .for_select
        position absolute
        right 0px
        top -3px
    .items
      margin-top 25px
      overflow hidden
      .Title
        font-weight normal
        position relative
        display flex
        justify-content space-between
        font-size 15px
        span:nth-child(1)
          color #333333
        span:nth-child(2)
          color #999999
          display inline-block
          margin-right 30px
          font-size 13px
          align-self center
        .for_select
          position absolute
          right 0px
      .content
        width 100%
        min-height 70px
        overflow hidden
        margin-top 19px
        color #666666
        font-size 15px
        font-weight normal
.lawyer
  width 100%
  .time_title
    width 95%
    margin 0px auto
    position relative
    .for_select
      position absolute
      right 10px
      top 5px
    span
      font-size 15px
      color #333333
  .time_items
    width 100%
    margin-top 20px
    height 350px
    overflow hidden
    .vcharts
      margin-top 15px
      width 100%
  .body_item
    width 100%
    overflow hidden
    padding 10px
    box-shadow 0px 0px 5px #cccccc
    position relative
    margin-bottom 20px
    .for_select
      position absolute
      right 10px
      top 10px
    .title
      color #666666
      font-size 15px
      text-align left
      margin 0px
  .deem_item
    width 100%
    box-shadow 0px 0px 5px #cccccc
    overflow hidden
    box-sizing border-box
    padding 10px
    position relative
    margin-bottom 20px
    .text
      color #666666
      font-size 15px
      display -webkit-box
      -webkit-box-orient vertical
      overflow hidden
      width 92%
    .for_select
      position absolute
      right 10px
      top 10px
    .more
      font-size 14px
      color #4B9EFB
      position absolute
      bottom 10px
      right 12px
  .lawyer_item
    width calc(100% - 4px)
    margin 0px auto
    border-radius 10px
    box-shadow 0px 0px 5px #cccccc
    overflow hidden
    padding 10px
    box-sizing border-box
    margin-bottom 20px
    position relative
    .for_select
      position absolute
      right 10px
      top 5px
    .text_area
      width 100%
      font-size 15px
      color #333333
      display flex
      justify-content space-between
    .appeal_title
      margin-top 20px
      color #666666
      font-size 15px
      margin-bottom 15px
    ._title
      color #333333
      font-size 15px
    .request
      color #666666
      font-size 15px
      margin-top 19px
      margin-bottom 20px
    .deem
      width 100%
      color #666666
      margin-top 20px
      font-size 15px
  .focus_items
    width 100%
    margin-bottom 20px
    overflo hidden
    position relative
    ._title
      color #666666
      font-size 15px
      text-align left
      font-weight normal
      width 90%
      float left
      margin-bottom 10px
    .for_select
      width 10%
      text-align right
      overflow hidden
      position absolute
      right 5px
    .tip_line
      width 100%
      display flex
      justify-content space-between
      div
        color #999999
        margin-top 10px
      div:nth-child(1)
        span, i
          font-size 15px
      div:nth-child(2)
        font-size 15px
        color #999999
.share
  color #fc5744
  font-weight 600
  font-size 16px
  margin-left 10px
  z-index 1000
  position relative
.scrollToTop
  width 40px
  height 40px
  position fixed
  bottom 50px
  right 30px
  border-radius 50%
  background-color #fc5744
  text-align center
  line-height 40px
  i
    color white
    font-size 20px !important
@media screen and (min-width: 1000px)
  .ctn
    width 60%
    margin 0px auto
</style>
